import * as types from './actionTypes';

export function OnSearch(selections: {
  value: string;
  item: { label: string; value: string };
  pagination: { pageIndex: number; pageSize: number };
}): types.OnSearch {
  return {
    type: types.ON_SEARCH,
    selections,
  };
}

export function OnPopulateAccountById(accountId: string): types.OnPopulateAccountById {
  const selections = {
    value: accountId,
    item: {
      label: 'Account Id',
      value: 'Account Id',
    },
    pagination: {
      pageIndex: 1,
      pageSize: 75,
    },
  };

  return {
    type: types.ON_POPULATE_ACCOUNT_BY_ID,
    selections,
  };
}

export function OnPopulateAccountInProgress(): types.OnPopulateAccountInProgress {
  return {
    type: types.ON_POPULATE_ACCOUNT_IN_PROGRESS,
  };
}

export function OnPopulateAccountSuccess(): types.OnPopulateAccountSuccess {
  return {
    type: types.ON_POPULATE_ACCOUNT_SUCCESS,
  };
}

export function OnPopulateAccountFailed(message: string): types.OnPopulateAccountFailed {
  return {
    type: types.ON_POPULATE_ACCOUNT_FAILED,
    errorMessage: message,
  };
}

export function OnSearchEmailorUsername(selections: {
  value: string;
  item: { label: string; value: string };
  pagination: { pageIndex: number; pageSize: number };
}): types.OnEmailOrUsernameSearch {
  return {
    type: types.ON_SEARCH_EMAIL_USERNAME,
    selections,
  };
}

export function OnSearchSuccess(results: any, status?: number): types.OnSearchSuccess {
  return {
    type: types.ON_SEARCH_SUCCESS,
    results,
    status,
  };
}

export function OnSearchFailed(message: string, status?: number): types.OnSearchFailed {
  return {
    type: types.ON_SEARCH_FAILED,
    errorMessage: message,
    status,
  };
}

export function OnSearchInProgress(): types.OnSearchInProgress {
  return {
    type: types.ON_SEARCH_IN_PROGRESS,
  };
}

export function OnUserAccountSelected(selecteduser: any, options: any = {}): types.OnUserAccountSelected {
  return {
    type: types.ON_USER_ACCOUNT_SELECTED,
    payload: selecteduser,
    options,
  };
}

export const SendActivationMail = (message: string): types.SendActivationMail => ({
  type: types.SEND_ACTIVATION_MAIL,
  meta: {
    successMessage: message,
  },
});

export function OnSearchMounted(): types.OnSearchMounted {
  return {
    type: types.SEARCH_PAGE_MOUNTED,
  };
}

export const OnUserSearchValueUpdate = payload => {
  return {
    type: types.ON_USER_SEARCH_VALUE_UPDATE,
    payload,
  };
};
