import { TENANT_STATIC_WEB_RESOURCES_ROOT_URL } from 'app/config/constants';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const BrandIcon = (props: any) => {
  const { tenantName } = props;
  const [userStyle, setUserStyle] = useState(null);

  useEffect(() => {
    setUserStyle({
      backgroundImage: `url(${TENANT_STATIC_WEB_RESOURCES_ROOT_URL}/assets/${tenantName}/images/${tenantName}.svg)`,
    });
  }, [tenantName]);

  return (
    <div className={`brand-icon client-${tenantName}`}>
      <Link to="/" className="user-logo" aria-label="User logo" style={userStyle}></Link>
    </div>
  );
};
