import './styles.scss';
import './header-search.scss';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { searchOptions } from 'app/modules/components/mocks/mockdata';
import CustomSelect from 'app/shared/components/select/select.component';
import { InputSearch } from 'app/modules/components/input/inputSearch';

const selectOptions: ISelectOption[] = searchOptions;

interface IProps {
  onSearch: (option: any) => void;
  selectedOptionsProps?: any;
}

interface IState {
  selectedOption: ISelectOption;
}

export class SearchView extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {
        label: this.props.selectedOptionsProps?.searchLabel ? this.props.selectedOptionsProps.searchLabel : '',
        value: this.props.selectedOptionsProps?.searchValue ? this.props.selectedOptionsProps.searchValue : 'Account Number',
      }, // default
    };
  }

  render() {
    return (
      <Row className="header-nav-row">
        <Col xs={12} sm={4} md={5} lg={5} className="search-content-dropdown">
          <CustomSelect
            value={this.state.selectedOption?.value || ''}
            options={selectOptions}
            customClass="custom-select-class"
            onSelectChange={event => {
              const result = selectOptions.find(i => i.value === event.target.value);
              this.setState({ selectedOption: result });
            }}
          />
        </Col>
        <Col xs={12} sm={8} md={7} lg={7} className="search-content">
          <InputSearch
            name="search"
            initValue={this.state.selectedOption?.label ? this.state.selectedOption.label : ''}
            placeHolder="Search here"
            onSearch={value => {
              const selections = { item: this.state.selectedOption, value: value.trim(), reinitiate: true };
              return this.props.onSearch(selections);
            }}
          />
        </Col>
      </Row>
    );
  }
}
