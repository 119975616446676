import * as types from './actionTypes';
import * as accessManagementTypes from '../../../pages/ip/data/actionTypes';
import * as compassSettingTypes from '../../../pages/compass/data/actionTypes';

const initialState = {
  accountAttributesLoading: false,
  accessManagementAccountSettingLoading: false,
  accessManagementCompassLoading: false,
};

const accountAttributesSetting = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_UPDATE_INPROGRESS:
      return {
        ...state,
        accountAttributesLoading: true,
      };
    case types.ON_UPDATE_SUCCESS:
    case types.ON_UPDATE_FAILED:
      return {
        ...state,
        accountAttributesLoading: false,
      };
    case accessManagementTypes.SAVE_IP_SCREEN:
      return {
        ...state,
        accessManagementAccountSettingLoading: true,
      };
    case accessManagementTypes.SAVE_IP_SCREEN_FAILED:
    case accessManagementTypes.SAVE_IP_SCREEN_SUCCESS:
      return {
        ...state,
        accessManagementAccountSettingLoading: false,
      };
    case compassSettingTypes.SAVE_COMPASS_SETTINGS:
      return {
        ...state,
        accessManagementCompassLoading: true,
      };
    case compassSettingTypes.SAVE_COMPASS_SETTINGS_FAILED:
    case compassSettingTypes.SAVE_COMPASS_SETTINGS_SUCCESS:
      return {
        ...state,
        accessManagementCompassLoading: false,
      };
    default:
      return state;
  }
};

export default accountAttributesSetting;
