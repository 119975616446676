import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as Types from './actionTypes';
import * as actions from './actions';
import * as serverAPI from 'app/modules/services/serverAPI';
import { selectedUser } from 'app/modules/pages/user-search/data/selector';

export function* getRevisionHistoryFn() {
  const user = yield select(selectedUser);

  const response = yield all([
    call(serverAPI.getRevisionHistory, user.account.accountId),
    call(serverAPI.getAccountNotesHistory, user.account.accountId),
  ]);

  if (response.some(item => item.status === 200 || item.status === '200')) {
    yield put(
      actions.getRevisionHistorySuccess({
        revisionHistory: response[0].data?.responseData?.value || {},
        accountNoteHistory: response[1].data?.responseData?.value || [],
      })
    );
  } else {
    yield put(actions.getRevisionHistoryFailed('Error get Audit History. Please try again.'));
  }
}

export default function* getRevisionHistorySaga() {
  yield takeEvery(Types.GET_REVISION_HISTORY, getRevisionHistoryFn);
}
