import './shared/layout/footer/footer.scss';
import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { REACT_APP_ASTM_DISPLAY_ADDRESS, REACT_APP_ENABLE_DYNAMIC_FOOTER, staticFooter } from './config/constants';

const year = new Date().getFullYear();

const NewFooter = ({ tenant }) => {
  const user = tenant.tenantName;
  let footer;
  if (REACT_APP_ENABLE_DYNAMIC_FOOTER === 'TRUE') {
    footer = tenant?.footer ?? {};
  } else {
    footer = staticFooter[user];
  }
  const userNumber = 0;
  const [userStyle, setUserStyle] = useState(null);

  useEffect(() => {
    setUserStyle({
      backgroundImage: `url(../../../../../content/logos/astm_footer_logo.svg`,
    });
  }, [userNumber]);

  return (
    <div className="footer bg-primary">
      <Container>
        <Row>
          <Col md="12">
            <p className="astm_international">
              {tenant.tenantName !== 'astm' && <span className="powerby astm-type-body">Powered by:</span>}
              <span className="logo" style={userStyle}></span>
            </p>
          </Col>

          <Col md="12">
            {footer?.socials && (
              <p className="icon-logos">
                {footer?.socials?.links.map((linkItem, linkIndex) => (
                  <a key={`social-${linkIndex}`} target="_blank" href={linkItem.url} rel="noopener noreferrer" aria-label={linkItem.label}>
                    {/* <i id="twitter-icon" className="icon-logo astm-icon fab fa-twitter-square"></i> */}
                    <i id={`${linkItem.label}-icon`} className={`icon-logo astm-icon ${linkItem?.['fa-icon']}`}></i>
                  </a>
                ))}
              </p>
            )}
          </Col>

          <Col md="12">
            {tenant.tenantName !== 'ASTM' && (
              <p id="display-address" className="copy_right astm-type-body">
                {footer?.displayAddress}
              </p>
            )}
            <p className="copy_right astm-type-body">
              Copyright © 1996 - {year} ASTM. All Rights Reserved. {REACT_APP_ASTM_DISPLAY_ADDRESS}
            </p>
          </Col>
          <Col md="12">
            <ul className="contact_us">
              {footer?.links && (
                <>
                  {footer?.links?.map((linkItem, linkIndex) => (
                    <li key={`link-${linkIndex}`}>
                      <a className="footer_bottom_link" rel="noopener noreferrer" href={linkItem.url} target="_blank">
                        {linkItem.label}
                      </a>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewFooter;
