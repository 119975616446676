import axios from 'axios';

const url = 'https://dev-manage.astm.org';

export const api = axios.create({
  baseURL: window.location.origin,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default api;

// const setupAxiosInterceptors = () => {
//   const onRequestSuccess = config => {
//     const newConfig = { ...config };
//     const token = sessionStorage.getItem('okta-token-storage');
//     const oktaToken = JSON.parse(token);

//     if (oktaToken !== null && typeof oktaToken.accessToken !== 'undefined') {
//       newConfig.headers.Authorization = `Bearer ${oktaToken.accessToken.accessToken}`;
//     }
//     return config;
//   };
//   const onResponseSuccess = response => response;
//   const onResponseError = err => {
//     const status = err.status || (err.response ? err.response.status : 0);
//     if (status === 403 || status === 401) {
//       //   onUnauthenticated();
//     }
//     return Promise.reject(err);
//   };
//   axios.interceptors.request.use(onRequestSuccess);
//   axios.interceptors.response.use(onResponseSuccess, onResponseError);
// };

api.interceptors.request.use(
  config => {
    const newConfig = { ...config };
    const token = sessionStorage.getItem('okta-token-storage');
    const oktaToken = JSON.parse(token);

    if (oktaToken !== null && typeof oktaToken.accessToken !== 'undefined') {
      newConfig.headers.Authorization = `Bearer ${oktaToken.accessToken.accessToken}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return error.response;
  }
);
