import React from 'react';
import { ListBox } from './listbox';
import { Item } from './models';

interface IProps {
  items: Item[];
}

interface IState {
  source: Item[];
  dest: Item[];
  addSelections: any[];
  removeSelections: any[];
  selections: any[];
}

export class DualListBox extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      source: props.items,
      dest: [],
      addSelections: [],
      removeSelections: [],
      selections: [],
    };
  }
  render() {
    return (
      <div className="row container d-flex justify-content-between">
        <div className="col-sm-5">
          <ListBox items={this.state.source} header="Specbuilder Members" handleChange={this.onAddChange} isShowSelectAll={true} />
        </div>
        <div className="col-sm-2 d-flex justify-content-center flex-column align-items-center">
          <i className="fas fa-chevron-circle-right fa-lg mb-4" onClick={this.onAddClick}></i>
          <i className="fas fa-chevron-circle-left fa-lg" onClick={this.onRemoveClick}></i>
        </div>
        <div className="col-sm-5">
          <ListBox items={this.state.dest} header="Group Administrators" handleChange={this.onRemoveChange} />
        </div>
      </div>
    );
  }

  onAddClick = () => {
    this.removeFromSource();
    this.setState({ dest: [...this.state.dest, ...this.state.addSelections], addSelections: [] });
  };

  removeFromSource = () => {
    const diff = this.state.source.filter(i => !this.state.addSelections.includes(i));
    this.setState({ source: diff });
  };

  addToSource = () => {
    const diff = this.state.dest.filter(i => !this.state.removeSelections.includes(i));
    this.setState({ source: [...this.state.source, ...this.state.removeSelections], dest: diff });
  };

  onRemoveClick = () => {
    this.addToSource();
    const diff = this.state.dest.filter(i => !this.state.removeSelections.includes(i));
    this.setState({ dest: diff, removeSelections: [] });
  };

  onAddChange = (addSelections: Item[]) => {
    return this.setState({ addSelections });
  };

  onRemoveChange = (removeSelections: Item[]) => {
    return this.setState({ removeSelections });
  };
}
