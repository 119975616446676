import { combineReducers } from 'redux';
import * as actions from './actions';

export const activeTab = (state = null, action): any => {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const mainReducer = combineReducers({
  activeTab,
});

export default mainReducer;

export type IMainReducerState = ReturnType<typeof mainReducer>;
