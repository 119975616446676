export const ON_ADD_IP_ROW = 'components/ipgenerator/ON_ADD_IP_ROW';
export const ON_REMOVE_IP_ROW = 'components/ipgenerator/ON_REMOVE_IP_ROW';
export const ON_DELETE_IP_RANGE = 'components/ipgenerator/ON_DELETE_IP_RANGE';
export const ON_POPULATE_IPS = 'components/ipgenerator/ON_POPULATE_IPS';
export const ON_POPULATE_IPS_REQUEST = 'components/ipgenerator/ON_POPULATE_IPS_REQUEST';
export const ON_IP_POPULATION_FAILED = 'components/ipgenerator/ON_IP_POPULATION_FAILED';
export const ON_IP_POPULATION_SUCCESS = 'components/ipgenerator/ON_IP_POPULATION_SUCCESS';
export const ON_IP_CONFLICTS = 'components/ipgenerator/ON_IP_CONFLICTS';

export interface OnPopulateIPs {
  type: typeof ON_POPULATE_IPS;
  ipSelections: [];
}

export interface OnPopulateIPsRequest {
  type: typeof ON_POPULATE_IPS_REQUEST;
}

export interface OnIPPopulationFailed {
  type: typeof ON_IP_POPULATION_FAILED;
  errorMessage: string;
}

export interface OnIPPopulationSuccess {
  type: typeof ON_IP_POPULATION_SUCCESS;
  ranges: any[];
}

export interface OnIPConflicts {
  type: typeof ON_IP_CONFLICTS;
  conflicts: any[];
}

export interface OnAddIPRow {
  type: typeof ON_ADD_IP_ROW;
  newRange: {};
}

export interface OnRemoveIPRow {
  type: typeof ON_REMOVE_IP_ROW;
  newRange: any;
}

export interface OnDeleteIPRange {
  type: typeof ON_DELETE_IP_RANGE;
  newRange: any;
}

export type IpGeneratorAction =
  | OnPopulateIPs
  | OnPopulateIPsRequest
  | OnIPPopulationFailed
  | OnIPPopulationSuccess
  | OnIPConflicts
  | OnAddIPRow
  | OnRemoveIPRow
  | OnDeleteIPRange;
