import * as Types from './actionTypes';

export const GetCompassSettings = () => ({
  type: Types.GET_COMPASS_SETTINGS,
});

export const UpdateCompassSettings = item => ({
  type: Types.UPDATE_COMPASS_SETTINGS,
  item,
});

export const GetSpecBuilderSettings = () => ({
  type: Types.GET_SPEC_BUILDER_SETTINGS,
});

export const UpdateSpecBuilderSettings = item => ({
  type: Types.UPDATE_SPEC_BUILDER_SETTINGS,
  item,
});

export const SaveCompassSettings = (settings: any) => ({
  type: Types.SAVE_COMPASS_SETTINGS,
  settings,
});

export const SaveSpecBuilderSettings = (settings: any) => ({
  type: Types.SAVE_SPEC_BUILDER_SETTINGS,
  settings,
});

export const SaveCompassSettingsSuccess = (message: string) => ({
  type: Types.SAVE_COMPASS_SETTINGS_SUCCESS,
  meta: { successMessage: message },
});

export const SaveCompassSettingsFailed = (message: string) => ({
  type: Types.SAVE_COMPASS_SETTINGS_FAILED,
  meta: { errorMessage: message },
});

export const SaveSpecBuilderSettingsFailed = (message: string) => ({
  type: Types.SAVE_SPEC_BUILDER_SETTINGS_FAILED,
  meta: { errorMessage: message },
});

export const SaveSpecBuilderSettingsSuccess = (message: string) => ({
  type: Types.SAVE_SPEC_BUILDER_SETTINGS_SUCCESS,
  meta: { successMessage: message },
});

export const GetApplicationSettings = () => ({
  type: Types.GET_APPLICATION_SETTINGS,
});

export const GetApplicationSettingsInprogress = () => ({
  type: Types.GET_APPLICATION_SETTINGS_INPROGRESS,
});

export const GetApplicationSettingsSuccess = (data: any) => ({
  type: Types.GET_APPLICATION_SETTINGS_SUCCESS,
  payload: data,
});

export const GetApplicationSettingsFailed = message => ({
  type: Types.GET_APPLICATION_SETTINGS_FAILED,
  meta: { errorMessage: message },
});
