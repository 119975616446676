import { combineReducers } from 'redux';
import components, { IComponentsState } from '../components/data/reducers';
import pages, { IPagesState } from '../pages/data/reducer';

export interface IEntitiesState {
  readonly components: IComponentsState;
  readonly pages: IPagesState;
}

const entities = combineReducers<IEntitiesState>({
  components,
  pages,
});

export default entities;
