import { ETabs } from 'app/modules/pages/models/pagesModel';

export const LOGIN_SUCCESS_PERSIST_USER = 'pages/main/LOGIN_SUCCESS_PERSIST_USER';
export const LOGIN_SUCCESS_PERSIST_USER_FULFILLED = 'pages/main/LOGIN_SUCCESS_PERSIST_USER_FULFILLED';
export const LOGIN_SUCCESS_PERSIST_USER_PENDING = 'pages/main/LOGIN_SUCCESS_PERSIST_USER_PENDING';
export const SET_ACTIVE_TAB = 'pages/main/SET_ACTIVE_TAB';

export interface PersistCurrentUser {
  type: typeof LOGIN_SUCCESS_PERSIST_USER;
}

export interface PersistCurrentUserSuccess {
  type: typeof LOGIN_SUCCESS_PERSIST_USER;
}

export interface PersistCurrentUserPending {
  type: typeof LOGIN_SUCCESS_PERSIST_USER_PENDING;
}

export type MainScreenAction = PersistCurrentUser | PersistCurrentUserSuccess | PersistCurrentUserPending;

export const PersistCurrentUser = data => ({
  type: LOGIN_SUCCESS_PERSIST_USER,
  payload: data,
});

export const setActiveTab = (activeTab: ETabs) => ({
  type: SET_ACTIVE_TAB,
  payload: activeTab,
});
