import { BREAK_POINT_SM } from './breakpoint.constants';
import { TENANT_LIST, ASTM_TENANT_ID, AUTHORITIES } from 'app/config/constants';

export const getAllUrlParams = (url: string) => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (/\[(\d+)?\]$/.exec(paramName)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (/\[\d+\]$/.exec(paramName)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const getCurrentPageIndex = (): number => {
  const params = getAllUrlParams(window.location.href);
  return Number(params['page']) || 0;
};

// this function is used to setup current page Index
export const onPushWindowHistory = (i: number) => {
  const params = getAllUrlParams(window.location.href);
  if (window.location.search === '') {
    window.history.pushState({}, null, `${window.location.pathname}?page=${i}`);
  } else {
    let paramStr = '';
    for (const param of Object.keys(params)) {
      if (param === 'page') {
        paramStr += `${param}=${i}&`;
      } else {
        paramStr += `${param}=${params[param]}&`;
      }
    }
    paramStr = paramStr.length > 0 && paramStr.substr(0, paramStr.length - 1);
    window.history.pushState({}, null, `${window.location.pathname.split('?')[0]}?${paramStr}`);
  }
};

export const setupCurrentPageList = (pagesCount, currentPage, callBack) => {
  const list = [];
  if (window.screen.width < BREAK_POINT_SM) {
    // show all if page less than 4
    if (pagesCount <= 4) {
      for (let pages = 0; pages < pagesCount; pages++) {
        list.push({
          label: pages + 1,
          value: pages,
        });
      }
    } else {
      // if its in first page or last page
      if (currentPage === 0 || currentPage === pagesCount - 1) {
        list.push({
          label: 1,
          value: 0,
        });
        list.push({
          label: '...',
          value: '...',
        });
        list.push({
          label: pagesCount,
          value: pagesCount - 1,
        });
      } else {
        // add the current page to the list
        if (currentPage + 2 === pagesCount) {
          list.push({
            label: 1,
            value: 0,
          });
          list.push({
            label: '...',
            value: '...',
          });
          list.push({
            label: currentPage + 1,
            value: currentPage,
          });
          list.push({
            label: pagesCount,
            value: pagesCount - 1,
          });
        } else {
          list.push({
            label: 1,
            value: 0,
          });
          list.push({
            label: '...',
            value: '...',
          });
          list.push({
            label: currentPage + 1,
            value: currentPage,
          });
          list.push({
            label: '...',
            value: '...',
          });
          list.push({
            label: pagesCount,
            value: pagesCount - 1,
          });
        }
      }
    }
  } else {
    // show all if page less than 6
    if (pagesCount <= 6) {
      for (let pages = 0; pages < pagesCount; pages++) {
        list.push({
          label: pages + 1,
          value: pages,
        });
      }
    } else {
      // if its in first two page or last two page
      if (currentPage === 0 || currentPage === 1 || currentPage === pagesCount - 1 || currentPage === pagesCount - 2) {
        list.push({
          label: 1,
          value: 0,
        });
        list.push({
          label: 2,
          value: 1,
        });
        if (currentPage === 1) {
          list.push({
            label: 3,
            value: 2,
          });
        }
        list.push({
          label: '...',
          value: '...',
        });
        if (currentPage === pagesCount - 2) {
          list.push({
            label: pagesCount - 2,
            value: pagesCount - 3,
          });
        }
        list.push({
          label: pagesCount - 1,
          value: pagesCount - 2,
        });
        list.push({
          label: pagesCount,
          value: pagesCount - 1,
        });
      } else {
        // add the current page to the list
        list.push({
          label: 1,
          value: 0,
        });
        list.push({
          label: '...',
          value: '...',
        });
        list.push({
          label: currentPage,
          value: currentPage - 1,
        });
        list.push({
          label: currentPage + 1,
          value: currentPage,
        });
        list.push({
          label: currentPage + 2,
          value: currentPage + 1,
        });
        list.push({
          label: '...',
          value: '...',
        });
        list.push({
          label: pagesCount,
          value: pagesCount - 1,
        });
      }
    }
  }

  callBack(list);
};

export const sortObjectComparer = (key: any, isDescending?: boolean) => (a, b) => {
  const getValue = value => (typeof value === 'string' ? value.toLowerCase() : value);
  const aValue = getValue(typeof key === 'function' ? key(a) : a[key]);
  const bValue = getValue(typeof key === 'function' ? key(b) : b[key]);

  if (isDescending) {
    if (!aValue) return -1;
    if (!bValue) return 1;
    return typeof bValue === 'string' ? bValue.localeCompare(aValue) : bValue - aValue;
  } else {
    if (!bValue) return -1;
    if (!aValue) return 1;
    return typeof aValue === 'string' ? aValue.localeCompare(bValue) : aValue - bValue;
  }
};

export const getTenantName = () => {
  const origin = window.location.origin;
  let tenantName = undefined;
  for (const key in TENANT_LIST) {
    if (origin.includes(TENANT_LIST[key])) {
      tenantName = key;
    }
  }
  return (tenantName || 'astm').toUpperCase();
};

export const getUpdateNotePayloadMap = (accountId: string, noteText: string) => {
  return {
    accountId,
    noteText,
  };
};

export const mergeRevisionAndAccountNoteHistory = (revisionHistory, accountNoteHistory) => {
  // the API response for revisionHistory and AccountNote History is not
  // similar and hence needs to be manually merged before UI can use it.

  // Instantiating an empty array.
  const data: any[] = [];

  Object.keys(revisionHistory).forEach(i => {
    data.push(...revisionHistory[i].map(j => ({ ...j, entity: i, isOpen: true, isNote: false })));
  });

  accountNoteHistory.forEach(item => {
    data.push({
      ...item,
      isOpen: true,
      isNote: true,
    });
  });

  data.sort((a, b) => {
    const aDate: any = new Date(a.modifiedDate);
    const bDate: any = new Date(b.modifiedDate);
    return bDate - aDate;
  });

  return data;
};

export function getRedirectURL(compassRoleIds, specBuilderRoleId, accountTypeId: number, tenantId: number, tenantRedirectURL) {
  // if account type is not Organization and tenant is ASTM return null
  if (accountTypeId && accountTypeId !== 1 && tenantId === ASTM_TENANT_ID) {
    return null;
  }

  const applicationRedirectUrls = tenantRedirectURL?.data?.responseData?.value?.applicationRedirectUrls || null;
  const defaultRedirectURL = tenantRedirectURL?.data?.responseData?.value?.default;
  if (compassRoleIds.length) {
    return applicationRedirectUrls?.find(item => item.applicationCode === 'COMPASS')?.redirectUrl || null;
  } else if (specBuilderRoleId) {
    return applicationRedirectUrls?.find(item => item.applicationCode === 'SB')?.redirectUrl || null;
  }

  if (tenantId === ASTM_TENANT_ID && defaultRedirectURL !== 'undefined') {
    return defaultRedirectURL;
  }

  return null;
}

export function getUserAdminRedirectUrl(tenantId, accountId, MAEUserID) {
  return `/tenantID/${tenantId}/accountID/${accountId}/UserID/${MAEUserID}/view`;
}

export function accountIdsWithUserAdminRole(accounts, role = AUTHORITIES.MAE_USER_ADMIN) {
  return (accounts || [])
    .filter(({ applicationRoleDetails }) =>
      Object.values(applicationRoleDetails).find((ap: [any]) => ap.find(({ roleCode }) => roleCode === role))
    )
    .map(item => item.accountId);
}

export function accountsWithUserAdminRole(accounts, role = AUTHORITIES.MAE_USER_ADMIN) {
  return (accounts || [])
    .filter(({ applicationRoleDetails }) =>
      Object.values(applicationRoleDetails).find((ap: [any]) => ap.find(({ roleCode }) => roleCode === role))
    )
    .map(item => item);
}

/*
 *
 * Added to validate role details. This function will
 * either be true or false.
 *
 * */

export function validateRoles(validRoles: string[] = [], roleList: string[] = []): boolean {
  return validRoles?.some((validRole: string) => roleList?.some((role: string) => role === validRole));
}

export const debounce = (fn, delay = 1000) => {
  let timerId = null;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};
