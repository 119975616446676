export const GET_REVISION_HISTORY = 'pages/audit/GET_REVISION_HISTORY';
export const GET_REVISION_HISTORY_SUCCESS = 'pages/audit/GET_REVISION_HISTORY_SUCCESS';
export const GET_REVISION_HISTORY_FAILED = 'pages/audit/GET_REVISION_HISTORY_FAILED';

export interface GetRevisionHistory {
  type: typeof GET_REVISION_HISTORY;
}

export interface GetRevisionHistorySuccess {
  type: typeof GET_REVISION_HISTORY_SUCCESS;
  data: { revisionHistory; accountNoteHistory };
}

export interface GetRevisionHistoryFailed {
  type: typeof GET_REVISION_HISTORY_FAILED;
  meta: { errorMessage: string };
}
