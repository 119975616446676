import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { ASTM_TENANT_ID } from 'app/config/constants';
import { hideSidebar } from 'app/shared/reducers/sidebar.reducer';
import isEmpty from 'lodash/isEmpty';

export const useDisplayMenu = () => {
  const dispatch = useDispatch();
  const showSidebar = useSelector((state: IRootState) => state.sidebar.showSidebar);
  const selectedTenant = useSelector((state: IRootState) => state.entities.pages.tenantSelectionPage.selectedTenant);
  const displayMenu = !isEmpty(selectedTenant);
  if (showSidebar && !displayMenu) dispatch(hideSidebar());
  return displayMenu;
};

export const useIsASTM = () => {
  const tenantId = useSelector((state: IRootState) => state.entities.pages.tenantSelectionPage.selectedTenant.tenantId);
  return tenantId === ASTM_TENANT_ID;
};
