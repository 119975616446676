import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCGEntitlementsForUser } from 'app/modules/services/serverAPI';

const SLICE_NAME = 'pages/baseUser';

export const getCGEntitlementsData = createAsyncThunk(`${SLICE_NAME}/getCGEntitlements`, async (payload: any) => {
  try {
    const result = await getCGEntitlementsForUser('MAE', payload.MAEUserID, payload.MAETenantID, payload.accountId);
    return result;
  } catch (err) {
    return err;
  }
});

const initialState = {
  cgEntitlementsData: {},
  isLoading: true,
  isError: false,
  error: null,
  hasAccountId: '',
  selectedTenant: {},
  currentUser: {},
  globalOktaAuthLoader: true,
  isMaeApp: false,
  delegationPath: '',
};

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setAccountId(state, action) {
      state.hasAccountId = action.payload;
    },
    setDelegationPath(state, action) {
      state.delegationPath = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setSelectedTenant(state, action) {
      state.selectedTenant = action.payload;
    },
    setGlobalOktaAuthLoader(state, action) {
      state.globalOktaAuthLoader = action.payload;
    },
    setIsMaeApp(state, action) {
      state.isMaeApp = action.payload;
    },
  },
  extraReducers(builder) {
    return builder
      .addCase(getCGEntitlementsData.pending, state => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getCGEntitlementsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cgEntitlementsData = action.payload || {};
      })
      .addCase(getCGEntitlementsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload || null;
        state.cgEntitlementsData = {};
      });
  },
});

// Selectors
export const isGetCGEntitlementsLoading = (state: any) => state.baseUser.isLoading;
export const cgEntitlementsAccountData = (state: any) => state.baseUser.cgEntitlementsData;
export const hasAccountId = (state: any) => state.baseUser.hasAccountId;
export const getSelectedTenant = (state: any) => state.baseUser.selectedTenant;
export const getCurrentUser = (state: any) => state.baseUser.currentUser;
export const getGlobalOktaAuthLoader = (state: any) => state.baseUser.globalOktaAuthLoader;
export const getIsMaeApp = (state: any) => state.baseUser.isMaeApp;
export const getDelegationPath = (state: any) => state.baseUser.delegationPath;

// actions, reducers
export const reducer = slice.reducer;
export const { setAccountId, setDelegationPath, setCurrentUser, setSelectedTenant, setGlobalOktaAuthLoader, setIsMaeApp } = slice.actions;

// Type
export type IBaseUserState = ReturnType<typeof slice.reducer>;
