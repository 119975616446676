import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NotificationType from '../model/enumerations/notification.model';

export interface INotificationState {
  showNotification: boolean;
  message: string;
  type: NotificationType;
}

const initialState: INotificationState = {
  showNotification: false,
  message: '',
  type: NotificationType.INFO,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    hideNotification(state) {
      state.showNotification = false;
    },
    showNotification(state, action: PayloadAction<{ message: string; type: NotificationType }>) {
      state.showNotification = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
  },
});

export const { hideNotification, showNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
