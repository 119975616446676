import * as Types from './actionTypes';

export const GetTenants = () => ({
  type: Types.GET_TENANTS,
});

export const GetTenantsSuccess = (tenants: ITenantsModel[]) => ({
  type: Types.GET_TENANTS_SUCCESS,
  tenants,
});

export const OnTenantSelection = (tenant: ITenantsModel) => ({
  type: Types.ON_TENANT_SELECTION,
  tenant,
});

export const GetTenantsInProgress = (payload: boolean) => ({
  type: Types.GET_TENANTS_IN_PROGRESS,
  payload,
});
