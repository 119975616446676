import { combineReducers } from 'redux';
import * as types from './actionTypes';
import * as compassTypes from '../../compass/data/actionTypes';
import accountAttributesSetting from 'app/modules/components/account-attributes/data/reducer';

export const applicationSettings = (state: any = {}, action: types.appSettingActionTypes) => {
  switch (action.type) {
    case types.GET_APPLICATION_SETTINGS_SUCCESS:
      return action.payload;
    case types.GET_APPLICATION_SETTINGS:
    case types.GET_APPLICATION_SETTINGS_FAILED:
    case types.GET_APPLICATION_SETTINGS_INPROGRESS:
      return {};
    default:
      return state;
  }
};

export const subscriptionSettings = (state: any = {}, action: compassTypes.appSettingActionTypes) => {
  switch (action.type) {
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_SUCCESS:
      return action.payload;
    case compassTypes.GET_SUBSCRIPTION_SETTINGS:
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_FAILED:
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_INPROGRESS:
      return {};
    default:
      return state;
  }
};

export const subscriptionSettingsLoading = (state = true, action: compassTypes.appSettingActionTypes) => {
  switch (action.type) {
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_SUCCESS:
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_FAILED:
      return false;
    case compassTypes.GET_SUBSCRIPTION_SETTINGS_INPROGRESS:
      return true;
    default:
      return state;
  }
};

export const applicationSettingsLoading = (state = true, action: types.appSettingActionTypes) => {
  switch (action.type) {
    case types.GET_APPLICATION_SETTINGS_SUCCESS:
    case types.GET_APPLICATION_SETTINGS_FAILED:
    case types.GET_APPLICATION_SETTINGS:
      return false;
    case types.GET_APPLICATION_SETTINGS_INPROGRESS:
      return true;
    default:
      return state;
  }
};

export const appSettingsPage = combineReducers({
  applicationSettings,
  subscriptionSettings,
  applicationSettingsLoading,
  subscriptionSettingsLoading,
  accountAttributesSetting,
});

export default appSettingsPage;

export type IAppSettingsPageState = ReturnType<typeof appSettingsPage>;
