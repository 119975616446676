import React, { useState } from 'react';
import './select.component.scss';

interface IOption {
  label: string | number;
  value: string | number;
  isDisable?: boolean;
}
interface ISelect {
  defulatValue?: string | number;
  options: IOption[];
  onSelectChange: Function;
  customClass?: string;
  customSelectClass?: string;
  selectLabel?: string;
  disabled?: boolean;
  value?: string | number;
  dataTestId?: string;
}

export const CustomSelect = (props: ISelect) => {
  return (
    <>
      <span className={`select-span-contianer ${props.customClass}`}>
        {props.selectLabel && <span className="sort-label astm-type-body">{props.selectLabel}</span>}
        <select
          id="types"
          disabled={props.disabled}
          value={props.value}
          onChange={$event => props.onSelectChange($event)}
          defaultValue={props.defulatValue}
          aria-label="select"
          data-testid={props.dataTestId}
          className={`select-search-select astm-type-body--lead ${props.customSelectClass} mb-0`}
        >
          {props.options &&
            props.options.map((option: IOption) => (
              <option disabled={option.isDisable ? true : null} value={option.value} key={option.value}>
                {option.label}{' '}
              </option>
            ))}
        </select>
        <i className="fas fa-sort select-sort-icon"></i>
      </span>
    </>
  );
};

export default CustomSelect;
