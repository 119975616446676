import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { hasAnyRole } from 'app/modules/services/security';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { useDispatch } from 'react-redux';
import { setIsMaeApp } from './shared/reducers/base-user.reducer';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
}

export const NoAccess = () => {
  return (
    <div className="switch-component-container" style={{ marginTop: 65 }}>
      <div className="switch-account-content">
        <div className="insufficient-authority mt-3">
          <div className="alert alert-danger">
            <p>Access Denied. You do not have permission to access MAE. Please contact your system administrator to request access.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export class AuthRoute extends React.Component<IOwnProps> {
  isAuthorized = () => {
    if (this.props.hasAnyAuthorities === undefined || this.props.hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyRole(this.props.hasAnyAuthorities);
  };

  render() {
    return this.isAuthorized() ? (
      <ErrorBoundary>
        <Route {...this.props} component={this.props.component} />
      </ErrorBoundary>
    ) : (
      <NoAccess />
    );
  }
}
