import * as actions from './actionTypes';

export function onPopulateIPs(selections: any): actions.OnPopulateIPs {
  return {
    type: actions.ON_POPULATE_IPS,
    ipSelections: selections,
  };
}

export function onPopulateIPsRequest(): actions.OnPopulateIPsRequest {
  return {
    type: actions.ON_POPULATE_IPS_REQUEST,
  };
}

export function onIPPopulationFailed(errorMessage: string): actions.OnIPPopulationFailed {
  return {
    type: actions.ON_IP_POPULATION_FAILED,
    errorMessage,
  };
}

export function onIPPopulationSuccess(data: any[]): actions.OnIPPopulationSuccess {
  return {
    type: actions.ON_IP_POPULATION_SUCCESS,
    ranges: data,
  };
}

export function onIPConflicts(data: any): actions.OnIPConflicts {
  return {
    type: actions.ON_IP_CONFLICTS,
    conflicts: data,
  };
}

export function onAddIPRow(range: any): actions.OnAddIPRow {
  return {
    type: actions.ON_ADD_IP_ROW,
    newRange: range,
  };
}

export function onRemoveIPRow(range: any): actions.OnRemoveIPRow {
  return {
    type: actions.ON_REMOVE_IP_ROW,
    newRange: range,
  };
}

export function onDeleteIPRange(range: any): actions.OnDeleteIPRange {
  return {
    type: actions.ON_DELETE_IP_RANGE,
    newRange: range,
  };
}
