import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createIdentityDelegationSession } from 'app/modules/services/serverAPI';

const SLICE_NAME = 'pages/identityDelegation';

export const createIdentityDelegation = createAsyncThunk(`${SLICE_NAME}/createIdentityDelegation`, async (payload: any) => {
  try {
    const result = await createIdentityDelegationSession(payload);
    return result;
  } catch (err) {
    return err;
  }
});

const initialState = {
  data: null as any,
  loading: false as boolean,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanIdentityDelegation(state) {
      state.data = null;
    },
  },
  extraReducers(builder) {
    return builder
      .addCase(createIdentityDelegation.fulfilled, (state, action) => {
        state.data = action.payload || null;
        state.loading = false;
      })
      .addCase(createIdentityDelegation.pending, state => {
        state.data = null;
        state.loading = true;
      })
      .addCase(createIdentityDelegation.rejected, state => {
        state.loading = false;
      });
  },
});

// Selectors
export const getIdentityDelegationLoadingState = (state: any) => state.identityDelegation.loading;

// actions, reducers
export const reducer = slice.reducer;
export const { cleanIdentityDelegation } = slice.actions;

// Type
export type IIdentityDelegationState = ReturnType<typeof slice.reducer>;
