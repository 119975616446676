import { QUICKSIGHT_API_URL } from 'app/config/constants';
import api from './AxiosInstance';
import axios from 'axios';

const baseAPI = '/api/platform/v1/';
const baseAPIV2 = '/api/platform/v2/';
export const baseAPIV3 = '/api/platform/v3/';
const baseAPIWithoutToken = '/api/platform/';

// const baseUrl = 'https://ae5c878711d6146e5b91d7d13238221b-07c5da76fa9e1b1a.elb.us-east-2.amazonaws.com:9310';
// const baseUrl = 'https://dev-manage.astm.org';
const baseUrl = '';
const path = '/api/core/account/';
const url = 'https://qa-manage.astm.org';
const url1 = 'https://aff019a817e434686a439b29588e0ca8-63ceca242cad2c95.elb.us-east-2.amazonaws.com:9500';

export async function fetchIPConflicts(): Promise<any> {
  return api.get(`${window.location.origin}/content/mocks/ipConflicts.json`);
}

export async function checkIPConflicts(accountId, tenantId, ipData): Promise<any> {
  return api.post(
    `${baseAPIV3}account/authentication/check-for-ip-conflicts-from-index?accountId=${accountId}&tenantId=${tenantId}`,
    ipData
  );
}

export async function searchByAccountName(value): Promise<any> {
  return api.get(`${baseUrl + path}getAccountByName?name=${value}`);
}

export async function searchByAccountNumber(value): Promise<any> {
  return api.get(`${baseUrl + path}getAccountByAccountNumber?accountNumber=${value}`);
}

export async function fetchStocks({ accountId }: { accountId: number }): Promise<any> {
  return api.get(`${baseAPIV2}account/product/entitlement/getProductEntilementDetails?accountId=${accountId}`);
}

export async function updateStockStatusCall(productItemEntitlementId: number, body: object): Promise<any> {
  return api.put(`${baseAPIV2}account/product/entitlement/update?productItemEntitlementId=${productItemEntitlementId}`, body);
}

export async function updateStockGracePeriod({ stock }: { stock: IUpdatedStock }): Promise<any> {
  return api.put(`${baseAPIV2}account/product/entitlement/update?productItemEntitlementId=${stock.productItemEntitlementId}`, {
    gracePeriod: stock.gracePeriod,
  });
}

export async function searchAccountsByAccountNumber(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/searchAccounts?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    accountNumber: String(value),
    tenantId: String(tId),
  });
  // return accountInfo;
}

export async function searchAccountsByTenantAccNumber(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/searchAccounts?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    tenantAccountNumber: String(value),
    tenantId: String(tId),
  });
}

export async function searchAccountsByAccountId(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/searchAccounts?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    accountId: String(value),
    tenantId: String(tId),
  });
}

export async function searchAccountsByAccountIdAndAccountName(tId, accountId, accountName, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/searchAccounts?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    accountId: String(accountId),
    accountName,
    tenantId: String(tId),
  });
}

export async function searchByEmailOrUsername(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}user/search-users?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    email: String(value),
    tenantId: String(tId),
  });
}

export async function searchByAccountIdAndUserId(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}user/search-users?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    accountId: String(value.accountId),
    userIds: [+value.userId],
    tenantId: String(tId),
  });
}

export async function searchByIpAddress(tenantId, ip, hideUserInfo = false): Promise<any> {
  return api.get(`${baseAPIV3}account/authentication/search-idx-account-by-ip?hideUserInfo=${hideUserInfo}&ip=${ip}&tenantId=${tenantId}`);
}

export async function getAllAccountGroups(): Promise<any> {
  return api.get(`${baseAPIV3}account/group/get-account-groups-by-ids?accountGroupIds=`);
}

export async function getTenantContact(tenantId, value): Promise<any> {
  return api.get(`${baseAPIV3}tenant/contact/get-tenant-contact?tenantId=${tenantId}`);
}

export async function getContentByCriteria(publisherCode, assetId, pageSize = 10): Promise<any> {
  const data = {
    PublisherCode: publisherCode,
    OPERAND: 'AND',
    ContentCode: `${publisherCode}|${assetId}*`,
  };
  const criteria = encodeURI(JSON.stringify(data));

  return api.get(`${baseAPIV3}content/get-content-by-criteria?criteria=${criteria}&pageSize=${pageSize}`);
}

export async function verifyContentEntitlement(accountIds, contentId, deliveryPlatformCodes): Promise<any> {
  return api.get(
    `${baseAPIV3}account/entitlement/idx/verify-content-entitlement?accountIds=${accountIds}&contentId=${contentId}&deliveryPlatformCodes=${deliveryPlatformCodes}`
  );
}

export async function verifyContentEntitlementDb(accountId, contentId, deliveryPlatformCodes): Promise<any> {
  return api.get(
    `${baseAPIV3}account/entitlement/idx/verify-content-entitlement-in-db?accountId=${accountId}&contentId=${contentId}&deliveryPlatformCodes=${deliveryPlatformCodes}`
  );
}

export async function getRevisionHistory(accountId): Promise<any> {
  return api.get(`${baseAPIV3}account/getRevisionHistory?accountId=${accountId}`);
}

export async function bulkUploadUsers(accountId, formData): Promise<any> {
  return api.post(`${baseAPIV3}bulk-upload/file-processor?accountId=${accountId}&uploadType=user`, formData);
}

export async function getBulkUploadMasterData(accountId, pageIndex = 1, pageSize = 50): Promise<any> {
  return api.get(`${baseAPIV3}bulk-upload/get-master-by-account?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
}

export async function getDownlodCsv(masterId): Promise<any> {
  return api.get(`${baseAPIV3}bulk-upload/download-report?masterId=${masterId}`);
}

export async function searchAccountsByAccountName(tId, value, pageIndex = 1, pageSize = 75): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/searchAccounts?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    accountName: String(value),
    tenantId: String(tId),
  });
}

export async function updateAccountAuthenticationSetting(accountId: number, userObj: any): Promise<any> {
  const newObj = {};
  Object.keys(userObj).forEach(key => (newObj[key] = JSON.stringify(userObj[key])));
  delete newObj['mfa'];
  return api.post(`${baseAPIV3}account/authentication/setting/update?accountId=${String(accountId)}`, {
    ...newObj,
  });
}

export async function registerNewUser(userObj): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/createUser`, [
    {
      ...userObj,
    },
  ]);
}

export async function addApplicationRole(userObj): Promise<any> {
  return api.post(
    `${baseAPIV2}account/user/application/role/addAccountApplicationUserRole?accountUserId=${userObj.accountUserId}&applicationId=${userObj.applicationId}&applicationRoleId=${userObj.applicationRoleId}`
  );
}

export async function associateUserWithAccount(userId: string, accountId: string): Promise<any> {
  return api.post(`${baseAPIV2}accountuser/associateUserWithAccount?accountId=${accountId}&userId=${userId}`);
}

export async function updateApplicationRole(role): Promise<any> {
  return api.put(
    `${baseAPIV2}account/user/application/role/update-account-application-user-role?accountApplicationUserRoleId=${role.accountApplicationUserRoleId}&applicationRoleId=${role.applicationRoleId}`
  );
}

export async function removeApplicationRole(accountApplicationUserRoleId): Promise<any> {
  return api.put(
    `${baseAPIV3}account/application/user/role/remove-account-application-user-role?accountApplicationUserRoleId=${accountApplicationUserRoleId}`
  );
}

export async function getEncryptedAccount(accountId): Promise<any> {
  return api.get(`${baseAPIV2}account/authentication/getEncryptedAccountAppletId?accountId=${accountId}`);
}

export async function getApplicationSettings(accountSettingId): Promise<any> {
  return api.get(
    `${baseAPIV2}account-application-authorization-setting/getAccountApplicationAuthenticationSetting?accountAuthenticationSettingId=${accountSettingId}`
  );
}

export async function getSubscriptionSettings(accountId): Promise<any> {
  return api.get(`${baseAPIV3}entitlements/subscription-application-setting/get-subscription-application-setting?accountId=${accountId}`);
}

export async function updateApplicationSettings(id, settings): Promise<any> {
  return api.put(
    `${baseAPIV2}account-application-authorization-setting/update?accountApplicationAuthenticationSettingId=${parseInt(id, 10)}`,
    {
      ...settings,
    }
  );
}

export async function updateSubscriptionSettings(id, settings): Promise<any> {
  return api.post(`${baseAPIV2}entitlements/subscriptionApplicationSetting/update?subscriptionApplicationSettingId=${id}`, {
    ...settings,
  });
}

export async function updateAccountSettings(accountId: number, accountSettingId: number, dataToUpdate: any): Promise<any> {
  return api.put(`${baseAPIV3}account/setting/update?accountId=${accountId}&accountSettingId=${accountSettingId}`, {
    ...dataToUpdate,
  });
}

export async function updateAccount(accountId: number, dataToUpdate: any): Promise<any> {
  return api.put(`${baseAPIV3}account/update?accountId=${accountId}`, dataToUpdate);
}

export async function fetchAllIndustries(): Promise<any> {
  return api.get(`${baseAPIV2}industry/getIndustry`);
}

export async function fetchAllPartners(tenantId): Promise<any> {
  return api.get(`${baseAPIV3}partner/get-partner-by-tenant-id?tenantId=${tenantId}`);
}

export async function fetchExistingUsers(emailStr, tId, accountId?, pageIndex?, pageSize?): Promise<any> {
  return api.post(`${baseAPIV2}user/search-users?pageIndex=${pageIndex ? pageIndex : 1}&pageSize=${pageSize ? pageSize : 50}`, {
    tenantId: tId,
    email: emailStr,
    accountId,
  });
}

export async function fetchSearchUsers(email, tenantId, accountId, accountUserActive, pageIndex = 1, pageSize = 50): Promise<any> {
  return api.post(`${baseAPIV2}user/search-users?pageIndex=${pageIndex}&pageSize=${pageSize}`, {
    fullEmail: email,
    tenantId,
    accountId,
    accountUserActive,
  });
}

export async function fetchAccountLocation(accountId): Promise<any> {
  return api.get(`${baseAPIV3}account/location/get-account-location?accountId=${accountId}`);
}

export async function fetchAccountDivision(accountId): Promise<any> {
  return api.get(`${baseAPIV3}account/division/get-account-division?accountId=${accountId}`);
}

export async function fetchRolesOfUserRegistration(id): Promise<any> {
  return api.get(`${baseAPIV2}entitlements/application-role/get-application-role?applicationId=${id}`);
}

export async function downloadFile(accountId, getNewVersion): Promise<any> {
  return api.get(`${baseAPIV3}applet/generate-applet-file?accountId=${accountId}&generateNewVersion=${getNewVersion ? 'true' : 'false'}`);
}

export async function getReportUrl(dashboardId: string): Promise<any> {
  return api.get(QUICKSIGHT_API_URL + dashboardId);
}

export async function createUser(userObj): Promise<any> {
  return api.post(`${baseAPIV3}user/create`, [
    {
      ...userObj,
    },
  ]);
}

export async function updateUser(userID, userObj): Promise<any> {
  return api.put(`${baseAPIV2}user/updateUser?userID=${userID}`, { ...userObj });
}

export async function updateAccountUser(accountUserIds, userObj): Promise<any> {
  return api.put(`${baseAPIV3}account/user/update-account-user?accountUserIds=${accountUserIds}`, { ...userObj });
}

export async function getTenants(): Promise<any> {
  return api.get(`${baseAPIV2}tenant/getTenants`);
}

export async function getTenantSetting(tenantId): Promise<any> {
  return api.get(`${baseAPIV3}tenant/get-tenant-setting?tenantId=${tenantId}`);
}

export async function getCGEntitlementsForUser(applicationCode, userId, tenantId, accountId?): Promise<any> {
  return api.get(
    `${baseAPIV3}account/entitlement/get-cg-entitlements-for-user?applicationCode=${applicationCode}&userId=${userId}&tenantId=${tenantId}${
      accountId ? `&accountId=${accountId}` : ''
    }`
  );
}

export async function generateJWTToken(payload): Promise<any> {
  return api.post(`${baseAPIV3}jwt/generate-jwt-token`, payload);
}

export async function getSpecbuilderAccountSettings(accountId): Promise<any> {
  return api.get(`${baseAPIV3}specbuilder/account-settings/${accountId}`);
}

export async function updateSpecbuilderAccountSettings(accountId, payload): Promise<any> {
  return api.put(`${baseAPIV3}specbuilder/account-settings/${accountId}`, payload);
}

export async function createNewAccountNote(payload: any): Promise<any> {
  return api.post(`${baseAPIV3}account/create-account-note`, payload);
}

export async function getAccountNotesHistory(accountId): Promise<any> {
  return api.get(`${baseAPIV3}account/get-account-notes?accountId=${accountId}`);
}

export async function loadTenantConfigurationUI(): Promise<any> {
  return axios.post(`${baseAPIWithoutToken}config/get-tenant-configuration-for-ui`);
  // return axios.get('http://localhost:4010/get-tenant-configuration-for-ui-astm');
}

export async function getUserByIds(ids: string[]): Promise<any> {
  return api.get(`${baseAPIV3}user/get-user-by-ids?id=${ids}`);
}

export async function getTenantRedirectURL(accountType: string, tenantCode: string): Promise<any> {
  return api.get(`${baseAPIV3}activation/get-redirect-urls?accountType=${accountType}&tenantCode=${tenantCode}`);
}

export async function createIdentityDelegationSession(payload): Promise<any> {
  return api.post(`${baseAPIV3}identity-delegation/create-identity-delegation-session`, payload);
}
