export const get = state => state.entities.pages.searchPage;

export const searchSelections = state => get(state).searchSelections;
export const searchResults = state => get(state).searchResults;
export const searchInProgress = state => get(state).searchInProgress;
export const searchError = state => get(state).searchError;
export const searchResStatus = state => get(state).searchResStatus;
export const selectedUser = state => get(state).selectedUser;
export const encryptedAccountId = state => get(state).EncryptedAccountId;
export const searchValue = state => get(state).userSearchParams.searchValue;
export const searchLabel = state => get(state).userSearchParams.searchLabel;
