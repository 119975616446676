import { put, takeEvery, fork, call, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { getTenants } from 'app/modules/services/serverAPI';
import { IRootState } from 'app/shared/reducers';
import { GetTenantsSuccess, GetTenantsInProgress } from './actions';
import * as actionTypes from './actionTypes';
import sortBy from 'lodash/sortBy';

export function* GetTenants() {
  const tenants = yield select((state: IRootState) => state.entities.pages.tenantSelectionPage.tenants);

  if (!isEmpty(tenants)) return;
  yield put(GetTenantsInProgress(true));
  const response = yield call(getTenants);
  if (response.status === 200 && response.data && !isEmpty(response.data.responseData)) {
    yield put(GetTenantsSuccess(sortBy(response.data.responseData, ['tenantName'])));
    yield put(GetTenantsInProgress(false));
  }
}

function* WatchTenantsPage() {
  yield takeEvery(actionTypes.GET_TENANTS, GetTenants);
}

export default function* tenantsSaga() {
  yield fork(WatchTenantsPage);
}
