export const prepareTableData = (result: any): IResultModel => {
  return { users: [], customers: result, columnsConfig: 0, emailSearchResult: [], ip: [], totalElements: 0 };
};

export const prepareIpSearchTableData = (result: any): IResultModel => {
  return { users: [], customers: [], columnsConfig: 2, emailSearchResult: [], ip: result, totalElements: 0 };
};

export const prepareUserTableData = (result: any): IResultModel => {
  const users: ISearchResult[] = [];
  const customers: ISearchResult[] = [];
  const emailSearchResult: IUserSearchResult[] = [];
  if (Array.isArray(result)) {
    result.forEach(i => {
      emailSearchResult.push(i);
    });
  } else if (emailSearchResult != null && typeof emailSearchResult === 'object') {
    emailSearchResult.push(result);
  }
  return { users, customers, columnsConfig: 1, emailSearchResult, ip: [], totalElements: 0 };
};
