export const GET_STOCKS = 'pages/stocks/GET_STOCKS';
export const GET_STOCKS_INPROGRESS = 'pages/stocks/GET_STOCKS_INPROGRESS';
export const GET_STOCKS_SUCCESS = 'pages/stocks/GET_STOCKS_SUCCESS';
export const GET_STOCKS_FAILED = 'pages/stocks/GET_STOCKS_FAILED';

export const UPDATE_STOCK = 'pages/stocks/UPDATE_STOCK';
export const UPDATE_STOCK_INPROGRESS = 'pages/stocks/UPDATE_STOCK_INPROGRESS';
export const UPDATE_STOCK_SUCCESS = 'pages/stocks/UPDATE_STOCK_SUCCESS';
export const UPDATE_STOCK_FAILED = 'pages/stocks/UPDATE_STOCK_FAILED';
export const UPDATE_STOCK_GRACE_FAILED = 'pages/stocks/UPDATE_STOCK_GRACE_FAILED';
export const UPDATE_STOCK_GRACE_SUCCESS = 'pages/stocks/UPDATE_STOCK_GRACE_SUCCESS';
export const UPDATE_STOCK_STATUS = 'pages/stocks/UPDATE_STOCK_STATUS';
export const UPDATE_STOCK_STATUS_FAILED = 'pages/stocks/UPDATE_STOCK_STATUS_FAILED';
export const UPDATE_STOCK_STATUS_SUCCESS = 'pages/stocks/UPDATE_STOCK_STATUS_SUCCESS';
export const UPDATE_STOCK_GRACE = 'pages/stocks/UPDATE_STOCK_GRACE';
export const UPDATE_STOCK_STATUS_INPROGRESS = 'pages/stocks/UPDATE_STOCK_STATUS_INPROGRESS';

export const SAVE_STOCK_UPDATES = 'pages/stocks/SAVE_STOCK_UPDATES';
export const SAVE_STOCK_UPDATES_INPROGRESS = 'pages/stocks/SAVE_STOCK_UPDATES_INPROGRESS';
export const SAVE_STOCK_UPDATES_SUCCESS = 'pages/stocks/SAVE_STOCK_UPDATES_SUCCESS';
export const SAVE_STOCK_UPDATES_FAILED = 'pages/stocks/SAVE_STOCK_UPDATES_FAILED';

export const ON_FILTER_ACCESS_EXPIRY = 'pages/stocks/ON_FILTER_ACCESS_EXPIRY';

export const STOCK_SELECTION_THRESHOLD_ERROR = 'pages/stocks/STOCK_SELECTION_THRESHOLD_ERROR';
export const BULK_STOCK_GRACE_PERIOD_SELECTION = 'pages/stocks/BULK_STOCK_GRACE_PERIOD_SELECTION';

export interface GetStocks {
  type: typeof GET_STOCKS;
}

export interface GetStocksInProgress {
  type: typeof GET_STOCKS_INPROGRESS;
}

export interface GetStocksSuccess {
  type: typeof GET_STOCKS_SUCCESS;
  result: IStockModel[];
}

export interface GetStocksFailed {
  type: typeof GET_STOCKS_FAILED;
  meta: { errorMessage: string };
}

export interface UpdateStock {
  type: typeof UPDATE_STOCK;
  stock: IStockModel;
}

export interface UpdateStockStatus {
  type: typeof UPDATE_STOCK_STATUS;
  action: {
    data: IStockModel;
    status: boolean;
  };
}

export interface UpdateStockGrace {
  type: typeof UPDATE_STOCK_GRACE;
  stocks: IUpdatedStock[];
}

export interface UpdateStockStatusInprogress {
  type: typeof UPDATE_STOCK_STATUS_INPROGRESS;
}

export interface UpdateStockSuccess {
  type: typeof UPDATE_STOCK_SUCCESS;
  stock: IStockModel;
}

export interface UpdateStockFailed {
  type: typeof UPDATE_STOCK_FAILED;
  meta: { errorMessage: string };
}

export interface UpdateStockGraceSuccess {
  type: typeof UPDATE_STOCK_GRACE_SUCCESS;
  meta: { successMessage: string };
}

export interface UpdateStockGraceFailed {
  type: typeof UPDATE_STOCK_GRACE_FAILED;
  meta: { errorMessage: string };
}

export interface SaveStockUpdates {
  type: typeof SAVE_STOCK_UPDATES;
  stocks: IStockModel[];
}

export interface SaveStockUpdatesInprogress {
  type: typeof SAVE_STOCK_UPDATES_INPROGRESS;
}

export interface SaveStockUpdatesSuccess {
  type: typeof SAVE_STOCK_UPDATES_SUCCESS;
  stocks: IStockModel[];
}

export interface SaveStockUpdatesFailed {
  type: typeof SAVE_STOCK_UPDATES_FAILED;
  meta: { errorMessage: string };
}

export interface OnFilterAccessExpiry {
  type: typeof ON_FILTER_ACCESS_EXPIRY;
  status: boolean;
}

export interface StockSelectionThresholdError {
  type: typeof STOCK_SELECTION_THRESHOLD_ERROR;
  meta: { errorMessage: string };
}

export interface BulkGradePeriodSelection {
  type: typeof BULK_STOCK_GRACE_PERIOD_SELECTION;
  gracePeriod: ISelectOption;
}

export type stockActionTypes =
  | GetStocks
  | GetStocksInProgress
  | GetStocksSuccess
  | GetStocksFailed
  | UpdateStock
  | UpdateStockGrace
  | UpdateStockFailed
  | UpdateStockSuccess
  | UpdateStockGraceFailed
  | SaveStockUpdates
  | SaveStockUpdatesFailed
  | SaveStockUpdatesSuccess
  | SaveStockUpdatesInprogress
  | OnFilterAccessExpiry
  | UpdateStockStatusInprogress
  | StockSelectionThresholdError
  | BulkGradePeriodSelection
  | UpdateStockGraceSuccess;
