import './newHeader.scss';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Storage } from 'react-jhipster';
import { Link, NavLink } from 'react-router-dom';
import { showSidebar } from 'app/shared/reducers/sidebar.reducer';
import { useDisplayMenu } from 'app/shared/hooks/Menu';
import { NavDropdown } from 'app/shared/layout/menus/menu-components';
import { hasAnyRole } from 'app/modules/services/security';
import { AUTHORITIES } from 'app/config/constants';
import { BrandIcon } from './shared/layout/header/brand-icon';
import { setGlobalOktaAuthLoader, setIsMaeApp } from './shared/reducers/base-user.reducer';

const NewHeader = props => {
  const dispatch = useDispatch();
  const displayMenu = useDisplayMenu();
  const [cookieMessageDisplay, setCookieMessageDisplay] = useState(!Cookies.get('cookieMessageHide'));
  const { authState, oktaAuth } = useOktaAuth();
  const tenant = useSelector(state => state.entities.pages.tenantSelectionPage.selectedTenant);
  const [userInfo, setUserInfo] = useState<any>({ firstName: '', lastName: '' });

  React.useEffect(() => {
    const setName = async () => {
      const prevAuth = oktaAuth.authStateManager.getAuthState();

      if (!prevAuth?.isAuthenticated) {
        const info = await oktaAuth.token.getUserInfo();
        setUserInfo(info);
      } else {
        setUserInfo({ firstName: prevAuth?.idToken?.claims?.firstName, lastName: prevAuth?.idToken?.claims?.lastName });
      }
    };

    if (authState && authState?.isAuthenticated) setName();
  }, [authState, oktaAuth]);

  const handleClose = () => {
    setCookieMessageDisplay(false);
    Cookies.set('cookieMessageHide', 'true');
  };

  const privatePolicy = (
    <a className="astm-link" href="https://www.astm.org/POLICY/privacy_policy.html">
      privacy policy
    </a>
  );

  return (
    <div className="header-wrapper">
      {cookieMessageDisplay && (
        <div className="alert astm-alert bg-dark--light rounded-0 border-0" role="alert">
          <span className="astm-alert-text">
            We use cookies, including third party cookies, to provide you with the best possible browsing experience. To learn more about
            cookies and our privacy practices, please review our {privatePolicy}, with updates effective May 25, 2018.
          </span>
          <button onClick={handleClose} type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div className="header-top">
        <div className="header-top-inner">
          <div className="menu-logo-container">
            {displayMenu && hasAnyRole([AUTHORITIES.SUPER_ADMIN, AUTHORITIES.READONLY_ADMIN]) && (
              <div
                data-testid="sidebar-action-btn"
                className="header-nav-link menu"
                aria-label="Menu"
                onClick={() => dispatch(showSidebar())}
              >
                <i className="fa fa-bars icon" />
                <span className="label label-menu">Menu</span>
              </div>
            )}
            <BrandIcon tenantName={props.tenant.tenantName} />
          </div>
          <div className="top-nav-menu">
            {hasAnyRole([AUTHORITIES.SUPER_ADMIN]) && tenant?.tenantName && (
              <div className="d-flex align-items-center mr-2 switch-tenant-section">
                <span className="mr-1 font-weight-bold">Tenant:</span>
                <span className="mr-2 font-weight-bold">{tenant?.tenantName}</span>
                {'|'}
                <Link data-testid="switch-tenant" aria-label="switch-tenant" to="/" className="astm-link font-weight-normal ml-2">
                  Switch Tenant
                </Link>
              </div>
            )}
            <div className="help-nav-menu">
              <NavLink to="/help">
                <div>Help</div>
              </NavLink>
            </div>
            <div className="user-nav-menu">
              <NavDropdown
                icon="fas fa-user-circle"
                name={`${userInfo?.lastName} ${userInfo?.firstName}`.substring(0, 20)}
                id="account-menu"
              >
                <a
                  data-testid="dropdown-test"
                  className="dropdown-item logout-btn"
                  onClick={async () => {
                    try {
                      dispatch(setIsMaeApp(false));
                      dispatch(setGlobalOktaAuthLoader(true));
                      Storage.session.remove('MAERoles');
                      window.sessionStorage.clear();
                      await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/` });
                    } catch (error) {
                      return null;
                    }
                  }}
                >
                  <i className="far fa-sign-out"></i> Sign out
                </a>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
