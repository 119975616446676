import * as Types from './actionTypes';

export const SaveIPScreen = data => ({
  type: Types.SAVE_IP_SCREEN,
  data,
});

export const SuccessSaveIPScreen = message => ({
  type: Types.SAVE_IP_SCREEN_SUCCESS,
  meta: { successMessage: message },
});

export const onPopulateSingleIPs = (selections: any) => ({
  type: Types.ON_POPULATE_SINGLE_IPS,
  ipSelections: selections,
});

export const onPopulateRangeIPs = (selections: any) => ({
  type: Types.ON_POPULATE_RANGE_IPS,
  ipSelections: selections,
});

export const onRemoveRangeIPRow = (range: any, idx: number) => ({
  type: Types.ON_REMOVE_RANGE_IP_ROW,
  item: range,
  index: idx,
});

export const onRemoveSingleIPRow = (range: any, idx: number) => ({
  type: Types.ON_REMOVE_SINGLE_IP_ROW,
  item: range,
  index: idx,
});

export const OnSaveFailed = (message: string) => ({
  type: Types.SAVE_IP_SCREEN_FAILED,
  meta: { errorMessage: message },
});

export const onUpdateAuthStatus = (data: string) => ({
  type: Types.UPDATE_BASIC_AUTH,
  payload: data,
});

export const OnUpdateFedSSO = (data: any) => ({
  type: Types.UPDATE_FED_SSO,
  payload: data,
});

export const OnUpdateJWT = (data: any) => ({
  type: Types.UPDATE_JWT,
  payload: data,
});

export const OnUpdateTenantAccNum = (accountNumber: string, partnerId: number) => ({
  type: Types.UPDATE_TENANT_ACC_NUM,
  payload: { accountNumber, partnerId },
});

export const OnUpdateApplet = (data: any) => ({
  type: Types.UPDATE_APPLET,
  payload: data,
});

export const BuildRemoteLogin = (getNewVersion: boolean) => ({
  type: Types.BUILD_REMOTE_LOGIN,
  getNewVersion,
});

export const BuildRemoteLoginSuccess = (id: string) => ({
  type: Types.BUILD_REMOTE_LOGIN_SUCCESS,
  payload: id,
});

export const BuildRemoteLoginFailed = (message: string) => ({
  type: Types.BUILD_REMOTE_LOGIN_FAILED,
  meta: { errorMessage: message },
});
