import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import { fetchIPConflicts } from 'app/modules/services/serverAPI';
import * as ipActionTypes from './actionTypes';
import * as actions from './actionCreators';

export function* generateIPs() {
  try {
    yield put(actions.onPopulateIPsRequest());
    const response = yield call(fetchIPConflicts);
    yield put(actions.onIPConflicts(response.data.values));
  } catch (error) {
    yield put(actions.onIPPopulationFailed(error.response.data.error));
  }
}

export function* watchIPSaga() {
  yield takeEvery(ipActionTypes.ON_POPULATE_IPS, generateIPs);
}

export default function* IPGeneratorSaga() {
  yield all([fork(watchIPSaga)]);
}
