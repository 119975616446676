import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction, MetaResult } from 'app/shared/reducers/action-type.util';
import * as types from 'app/modules/pages/user-search/data/actionTypes';
import { AUTHORITIES } from 'app/config/constants';

const initialState = {
  selectedUser: null,
  compassRoles: null,
  specBuilderRoles: null,
  pubAssistantRoles: null,
  memberRoles: null,
  contentViewerRoles: null,
  publicRoles: null,
  maeRoles: null,
  accountLocations: null,
  accountDivisions: null,
  accountSettings: null,
};

const slice = createSlice({
  name: 'pages/user-administration',
  initialState,
  reducers: {
    selectUser(state, { payload: selectedUser }: PayloadAction<any>) {
      state.selectedUser = selectedUser;
    },
    fetchCompassRoles() {},
    fetchCompassRolesSuccess(state, { payload: compassRoles }: PayloadAction<any[]>) {
      state.compassRoles = compassRoles;
    },
    fetchSpecbuilderRoles() {},
    fetchSpecbuilderRolesSuccess(state, { payload: specBuilderRoles }: PayloadAction<any[]>) {
      state.specBuilderRoles = specBuilderRoles;
    },
    fetchPubAssistantRoles() {},
    fetchPubAssistantRolesSuccess(state, { payload: pubAssistantRoles }: PayloadAction<any[]>) {
      state.pubAssistantRoles = pubAssistantRoles;
    },
    fetchMemberRoles() {},
    fetchMemberRolesSuccess(state, { payload: memberRoles }: PayloadAction<any[]>) {
      state.memberRoles = memberRoles;
    },
    fetchContentViewerRoles() {},
    fetchContentViewerRolesSuccess(state, { payload: contentViewerRoles }: PayloadAction<any[]>) {
      state.contentViewerRoles = contentViewerRoles;
    },
    fetchPublicRoles() {},
    fetchPublicRolesSuccess(state, { payload: publicRoles }: PayloadAction<any[]>) {
      state.publicRoles = publicRoles;
    },
    fetchMAERoles() {},
    fetchMAERolesSuccess(state, { payload: maeRoles }: PayloadAction<any[]>) {
      const removedAccountAdmin = maeRoles.filter(role => role.roleCode === AUTHORITIES.MAE_USER_ADMIN);
      state.maeRoles = removedAccountAdmin;
    },
    fetchAccountLocation(state, action: PayloadAction<number>) {},
    fetchAccountLocationSuccess(state, { payload: accountLocations }: PayloadAction<any[]>) {
      state.accountLocations = accountLocations;
    },
    fetchAccountDivision(state, action: PayloadAction<number>) {},
    fetchAccountDivisionSuccess(state, { payload: accountDivisions }: PayloadAction<any[]>) {
      state.accountDivisions = accountDivisions;
    },
    fetchAccountSettings(state, action: PayloadAction<number>) {},
    fetchAccountSettingsSuccess(state, { payload: accountSettings }: PayloadAction<any[]>) {
      state.accountSettings = accountSettings;
    },
    saveUser(state, action) {},
    updateUserDetails(state, action) {},
    updateUser(state, action) {},
    resendActivationEmail(state, action) {},
    setResult(state, action: PayloadAction<MetaResult>) {},
  },
  extraReducers(builder) {
    builder.addCase(types.ON_USER_ACCOUNT_SELECTED, state => {
      return { ...initialState, selectedUser: state.selectedUser };
    });
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;

export type IUserAdministrationState = ReturnType<typeof slice.reducer>;
