import './sidebar.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { hideSidebar } from 'app/shared/reducers/sidebar.reducer';
import { useIsASTM } from 'app/shared/hooks/Menu';
import { hasAnyRole } from 'app/modules/services/security';
import { AUTHORITIES, PIM_UI_URL } from 'app/config/constants';

export const Sidebar = (props: StateProps) => {
  const { sidebar } = props;
  const [sidebarHight, setSidebarHeight] = useState(0);
  const sidebarClasses = sidebar.showSidebar === null ? 'hidden' : sidebar.showSidebar ? 'side show-sidebar' : 'side hide-sidebar';
  const isASTM = useIsASTM();

  useEffect(() => {
    setSidebarHeight(window.innerHeight);
    let interval;
    if (!interval) {
      interval = setInterval(() => {
        if (sidebarHight !== window.innerHeight) {
          setSidebarHeight(window.innerHeight);
        }
      }, 200);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  return (
    <div className={sidebarClasses} onClick={e => e.stopPropagation()} style={{ height: sidebarHight }} id="sidebar">
      <p onClick={() => props.hideSidebarAction()} data-testid="sidebar-close" className="sidebar-close astm-type-body">
        <i className="fas fa-times close-icon"></i> Close
      </p>
      <ul className="titleMenu text-secondary pl-4">
        <li>
          <Link data-testid="accountsEntLink" to="/" onClick={() => props.hideSidebarAction()}>
            Accounts & Entitlements
          </Link>
        </li>

        <li>
          <ul className="pl-4">
            <li className="mt-4">
              {!isASTM && (
                <Link
                  to="/report/usageSummary"
                  data-testid="subtitle-menu"
                  className="subtitle-menu"
                  onClick={() => props.hideSidebarAction()}
                >
                  Subscription Usage Summary
                </Link>
              )}
            </li>
          </ul>
        </li>
      </ul>
      {hasAnyRole([AUTHORITIES.PIM_SUPER_ADMIN, AUTHORITIES.PIM_READ_ONLY_ADMIN]) && (
        <>
          <hr className="lineMenu" />
          <ul className="text-secondary pl-4">
            <li>
              <a href={PIM_UI_URL}>
                Product Information Management <i className="fas fa-external-link-alt ml-2 text-secondary"></i>
              </a>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  hideSidebarAction: hideSidebar,
};

type StateProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
