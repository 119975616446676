import React from 'react';
import { Spinner } from 'reactstrap';
import './spinner.scss';

type LoaderSpinnerProps = {
  customClass?: string;
};

export const LoaderSpinner = (props: LoaderSpinnerProps) => {
  return (
    <div className={`mask-cover ${props?.customClass}`}>
      <Spinner animation="border" role="status" className="spinner-icon"></Spinner>
    </div>
  );
};

export default LoaderSpinner;
