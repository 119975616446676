import { combineReducers } from 'redux';
import searchPage, { ISearchPageState } from '../user-search/data/reducers';
import { reducer as userAdministration, IUserAdministrationState } from '../user-administration/data/reducer';
import stocksPage, { IStocksPageState } from '../stocks/data/reducer';
import appSettingsPage, { IAppSettingsPageState } from '../appsettings/data/reducer';
import tenantSelectionPage, { ITenantSelectionPageState } from '../landing-home/data/reducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import mainReducer, { IMainReducerState } from '../main/data/reducer';
import auditHistoryReducer, { IAuditHistoryPageState } from '../audit/data/reducer';

export interface IPagesState {
  readonly searchPage: ISearchPageState;
  readonly userAdministration: IUserAdministrationState;
  readonly stocksPage: IStocksPageState;
  readonly appSettingsPage: IAppSettingsPageState;
  readonly mainReducer: IMainReducerState;
  readonly tenantSelectionPage: ITenantSelectionPageState;
  readonly auditHistoryReducer: IAuditHistoryPageState;
}

const blackListedSearchPage = ['searchResults', 'searchError', 'searchSelections'];

const persistSearchPageConfig = {
  key: 'searchPage',
  storage: storageSession,
  blacklist: blackListedSearchPage,
};

const persistUserAdministration = {
  key: 'userAdministration',
  storage: storageSession,
  blacklist: [],
};

const persistTenantSelection = {
  key: 'tenantSelectionPage',
  storage: storageSession,
  blacklist: [],
};

const pages = combineReducers<IPagesState>({
  searchPage: persistReducer(persistSearchPageConfig, searchPage),
  userAdministration: persistReducer(persistUserAdministration, userAdministration),
  stocksPage,
  appSettingsPage,
  mainReducer,
  tenantSelectionPage: persistReducer(persistTenantSelection, tenantSelectionPage),
  auditHistoryReducer,
});

export default pages;
