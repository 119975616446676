export const get = state => state.entities.pages.appSettingsPage;

export const compassSettings = state => get(state).compassSettings;

export const sepcBuilderSettings = state => get(state).sepcBuilderSettings;

export const applicationSettings = state => get(state).applicationSettings;

export const subscriptionSettings = state => get(state).subscriptionSettings;

export const applicationSettingsLoading = state => get(state).applicationSettingsLoading;

export const subscriptionSettingsLoading = state => get(state).subscriptionSettingsLoading;
