import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import reducer from 'app/shared/reducers';
import errorMiddleware from './error-middleware';
import notificationMiddleware from './notification-middleware';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import appRootSaga from 'app/modules/store/saga';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['entities', 'sidebar'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware();

const defaultMiddlewares = [thunkMiddleware, errorMiddleware, notificationMiddleware, sagaMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [...defaultMiddlewares],
});

sagaMiddleware.run(appRootSaga);

export default store;
