import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './config/store';
import ErrorBoundary from './shared/error/error-boundary';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from './appRoutes';
import { OktaAuth } from '@okta/okta-auth-js';
import { CALLBACK_PATH, oktaConfig, LOGIN_PAGE_URL } from 'app/config/constants';
import { getTenantName } from './shared/util/common-utils';
import { loadTenantConfigurationUI } from './modules/services/serverAPI';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoaderSpinner from './shared/layout/spinner/spinner';
import { persistStore } from 'redux-persist';

export const persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, staleTime: Infinity, cacheTime: Infinity, refetchOnWindowFocus: false } },
});

const container = document.getElementById('root');
const root = createRoot(container);

// To handle redirection after authentication happens.
const basePath = window.location.href;
if (basePath.includes('/account/')) {
  if (!sessionStorage.getItem('basePath')) {
    sessionStorage.setItem('basePath', basePath);
  }
}
if (basePath.includes('/identitydelegation')) {
  if (!sessionStorage.getItem('basePath')) {
    sessionStorage.setItem('basePath', basePath);
  }
}

const authClient = tenant =>
  new OktaAuth({
    issuer: tenant.issuerURL,
    clientId: tenant.clientId,
    redirectUri: window.location.origin + CALLBACK_PATH,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    postLogoutRedirectUri: window.location.origin,
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 60,
      // secure: false, // This option is only relevant if storage is set to cookie and false option is only for testing
      storage: 'sessionStorage', // other possible values are localStorage (or) sessionStorage
    },
  });

const getTenantDataStatic = () => ({
  issuerURL: oktaConfig[getTenantName()].issuer,
  clientId: oktaConfig[getTenantName()].clientId,
  tenantName: getTenantName(),
  tenantCode: getTenantName(),
  loginURL: LOGIN_PAGE_URL[getTenantName()],
});

const render = (Component, tenant) => {
  // eslint-disable-next-line react/no-render-return-value
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={<LoaderSpinner customClass="persist-gate" />} persistor={persistor}>
            <Router>
              <Component tenant={tenant} authClient={authClient(tenant)} />
            </Router>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
};
(async () => {
  let tenantData: any = {};
  try {
    //  If the below flag returns true, MAE UI will make an API call to get the tenant details.
    if (process.env.ENABLE_DYNAMIC_WL_TENANT_CONFIGURATION === 'TRUE') {
      let response;
      const localTenantConfig = sessionStorage.getItem('tenantConfiguration');

      if (localTenantConfig) {
        response = JSON.parse(localTenantConfig);
      } else {
        response = await loadTenantConfigurationUI();
        sessionStorage.setItem('tenantConfiguration', JSON.stringify(response));
      }

      const data = response.data.responseData.value;
      tenantData.issuerURL = data['issuer-uri'];
      tenantData.clientId = data['client-id'];
      tenantData.tenantName = data.tenantName;
      tenantData.tenantCode = data.tenantCode;
      tenantData.loginURL = data['login-page-url'];
      tenantData.footer = data.footer;
    } else {
      //  If its false or undefined then revert back to original values to access the tenant configuration.
      tenantData = getTenantDataStatic();
    }
    render(AppRoutes, tenantData);
  } catch (err) {
    //  If its false or undefined then revert back to original values to access the tenant configuration.
    tenantData = getTenantDataStatic();
    render(AppRoutes, tenantData);
  }
})();
