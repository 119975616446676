import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateJWTToken } from 'app/modules/services/serverAPI';

const SLICE_NAME = 'pages/jwtLogin';

export const createJWTToken = createAsyncThunk(`${SLICE_NAME}/generateJWTToken`, async (payload: any) => {
  try {
    const result = await generateJWTToken(payload);
    return result;
  } catch (err) {
    return err;
  }
});

const initialState = {
  data: null as any,
  loading: false as boolean,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanJWTData(state) {
      state.data = null;
    },
  },
  extraReducers(builder) {
    return builder
      .addCase(createJWTToken.fulfilled, (state, action) => {
        state.data = action.payload || null;
        state.loading = false;
      })
      .addCase(createJWTToken.pending, state => {
        state.data = null;
        state.loading = true;
      })
      .addCase(createJWTToken.rejected, state => {
        state.loading = false;
      });
  },
});

// Selectors
export const getJWTLoadingState = (state: any) => state.jwtLogin.loading;

// actions, reducers
export const reducer = slice.reducer;
export const { cleanJWTData } = slice.actions;

// Type
export type IJWTLoginState = ReturnType<typeof slice.reducer>;
