import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { ASTM_TENANT_ID, AUTHORITIES } from 'app/config/constants';
import { hasAnyRole } from 'app/modules/services/security';

export const NO_COMPASS_ROLE = 'No Compass Role';
export const NO_SPECBUILDER_ROLE = 'No Specbuilder Role';
export const NO_MEMBER_ROLE = 'No Member Role';
export const NO_CONTENT_VIEWER_ROLE = 'No Content Viewer Role';
export const NO_PUBLIC_ROLE = 'No Web Store Role';
export const NO_MAE_ROLE = 'No MAE Role';
export const NO_PUB_ASSISTANT_ROLE = 'No Publishing Assistant Role';

const PERSON_ACCOUNT_TYPE_ID = 2;

interface UserRolesProps {
  formData: any;
  isReadOnly: boolean;
  updateFormData: (newFormData: any) => void;
  isCompassRoleActive: boolean;
  isSBRoleActive: boolean;
}

const UserRoles = (props: UserRolesProps) => {
  const compassRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.compassRoles || []);
  const specBuilderRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.specBuilderRoles || []);
  const pubAssistantRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.pubAssistantRoles || []);
  const memberRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.memberRoles || []);
  const contentViewerRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.contentViewerRoles || []);
  const publicRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.publicRoles || []);
  const maeRoles = useSelector((state: IRootState) => state.entities.pages.userAdministration.maeRoles || []);
  const selectedTenant = useSelector((state: IRootState) => state.entities.pages.tenantSelectionPage.selectedTenant);

  const [noCompassRoles, setNoCompassRoles] = useState(!props.formData.compassRoleIds.length);
  const [noPublicRoles, setNoPublicRoles] = useState(!props.formData.publicRoleIds.length); // compassRoleIds pending
  const [noMAERoles, setNoMAERoles] = useState(!props.formData.maeRoleIds.length);

  const isMemberRoleEnabled = useSelector(
    (state: IRootState) => state.entities.pages.searchPage.selectedUser.account.accountTypeId === PERSON_ACCOUNT_TYPE_ID
  );

  useEffect(() => {
    setNoCompassRoles(!props.formData.compassRoleIds.length);
  }, [props.formData.compassRoleIds.length]);

  const RolesCheck = ({ title, isDisabled = false, noRoleLabel, noRoles, noRolesOnChange, roles, isChecked, onChange }) => (
    <Col xs={4} className="mt-3">
      <b className="astm-type-heading--h5-bold">{title}</b>
      <div className="my-1">
        <input
          type="checkbox"
          data-testid={noRoleLabel}
          className="mr-2"
          disabled={props.isReadOnly || isDisabled}
          onChange={noRolesOnChange}
          checked={noRoles}
        />
        {noRoleLabel}
      </div>
      {roles.map(({ applicationRoleId, roleName }) => (
        <div key={applicationRoleId} className="my-1">
          <input
            type="checkbox"
            className="mr-2"
            data-testid={`role-input-${applicationRoleId}`}
            disabled={props.isReadOnly || isDisabled}
            value={applicationRoleId}
            onChange={({ target }) => onChange(applicationRoleId, target.checked)}
            checked={isChecked(applicationRoleId)}
          />
          {roleName}
        </div>
      ))}
    </Col>
  );

  return (
    <Row>
      {props.isCompassRoleActive && (
        <RolesCheck
          title="Compass Role"
          noRoleLabel={NO_COMPASS_ROLE}
          noRoles={noCompassRoles}
          roles={compassRoles.filter(({ applicationRoleId }) => !hasAnyRole([AUTHORITIES.MAE_USER_ADMIN]) || applicationRoleId !== 1)}
          isChecked={applicationRoleId => props.formData.compassRoleIds.includes(applicationRoleId)}
          noRolesOnChange={({ target }) => {
            setNoCompassRoles(target.checked);
            if (target.checked) props.updateFormData({ ...props.formData, compassRoleIds: [] });
          }}
          onChange={(applicationRoleId, checked) => {
            let compassRoleIds = props.formData.compassRoleIds;
            if (checked && !compassRoleIds.includes(applicationRoleId)) compassRoleIds = [...compassRoleIds, applicationRoleId];
            if (!checked && compassRoleIds.includes(applicationRoleId))
              compassRoleIds = compassRoleIds.filter(id => applicationRoleId !== id);
            props.updateFormData({ ...props.formData, compassRoleIds });
            setNoCompassRoles(!compassRoleIds.length);
          }}
        />
      )}
      {props.isSBRoleActive && (
        <Col xs={4} className="mt-3">
          <b className="astm-type-heading--h5-bold">Specbuilder Role</b>
          {[{ applicationRoleId: 0, roleName: NO_SPECBUILDER_ROLE }, ...specBuilderRoles].map(({ applicationRoleId, roleName }) => (
            <div key={applicationRoleId} className="my-1">
              <input
                type="radio"
                className="mr-2"
                data-testid={`sb-input-${applicationRoleId}`}
                disabled={props.isReadOnly || (applicationRoleId === 8 && !hasAnyRole([AUTHORITIES.SUPER_ADMIN]))}
                value={applicationRoleId}
                onChange={() => props.updateFormData({ ...props.formData, specBuilderRoleId: applicationRoleId })}
                checked={props.formData.specBuilderRoleId === applicationRoleId}
              />
              {roleName}
            </div>
          ))}
        </Col>
      )}
      {isMemberRoleEnabled && (
        <>
          <Col xs={4} className="mt-3">
            <b className="astm-type-heading--h5-bold">Member Role</b>
            {[{ applicationRoleId: 0, roleName: NO_MEMBER_ROLE }, ...memberRoles].map(({ applicationRoleId, roleName }) => (
              <div key={applicationRoleId} className="my-1">
                <input
                  type="radio"
                  className="mr-2"
                  data-testid={`member-input-${applicationRoleId}`}
                  disabled={props.isReadOnly}
                  value={applicationRoleId}
                  onChange={() => props.updateFormData({ ...props.formData, memberRoleId: applicationRoleId })}
                  checked={props.formData.memberRoleId === applicationRoleId}
                />
                {roleName}
              </div>
            ))}
          </Col>
          <Col xs={4} className="mt-3">
            <b className="astm-type-heading--h5-bold">Content Viewer Role</b>
            {[{ applicationRoleId: 0, roleName: NO_CONTENT_VIEWER_ROLE }, ...contentViewerRoles].map(({ applicationRoleId, roleName }) => (
              <div key={applicationRoleId} className="my-1">
                <input
                  type="radio"
                  className="mr-2"
                  data-testid={`content-input-${applicationRoleId}`}
                  disabled={props.isReadOnly}
                  value={applicationRoleId}
                  onChange={() => props.updateFormData({ ...props.formData, contentViewerRoleId: applicationRoleId })}
                  checked={props.formData.contentViewerRoleId === applicationRoleId}
                />
                {roleName}
              </div>
            ))}
          </Col>
        </>
      )}
      {!hasAnyRole([AUTHORITIES.MAE_USER_ADMIN]) && (
        <>
          <RolesCheck
            title="Web Store Role"
            noRoleLabel={NO_PUBLIC_ROLE}
            noRoles={noPublicRoles}
            roles={publicRoles}
            isChecked={applicationRoleId => props.formData.publicRoleIds.includes(applicationRoleId)}
            noRolesOnChange={({ target }) => {
              setNoPublicRoles(target.checked);
              if (target.checked) props.updateFormData({ ...props.formData, publicRoleIds: [] });
            }}
            onChange={(applicationRoleId, checked) => {
              let publicRoleIds = props.formData.publicRoleIds;
              if (checked && !publicRoleIds.includes(applicationRoleId)) publicRoleIds = [...publicRoleIds, applicationRoleId];
              if (!checked && publicRoleIds.includes(applicationRoleId))
                publicRoleIds = publicRoleIds.filter(id => applicationRoleId !== id);
              props.updateFormData({ ...props.formData, publicRoleIds });
              setNoPublicRoles(!publicRoleIds.length);
            }}
          />
          <RolesCheck
            title="Administration Role"
            noRoleLabel={NO_MAE_ROLE}
            noRoles={noMAERoles}
            roles={maeRoles}
            isDisabled={!hasAnyRole([AUTHORITIES.SUPER_ADMIN])}
            isChecked={applicationRoleId => props.formData.maeRoleIds.includes(applicationRoleId)}
            noRolesOnChange={({ target }) => {
              setNoMAERoles(target.checked);
              if (target.checked) props.updateFormData({ ...props.formData, maeRoleIds: [] });
            }}
            onChange={(applicationRoleId, checked) => {
              let maeRoleIds = props.formData.maeRoleIds;
              if (checked && !maeRoleIds.includes(applicationRoleId)) maeRoleIds = [...maeRoleIds, applicationRoleId];
              if (!checked && maeRoleIds.includes(applicationRoleId)) maeRoleIds = maeRoleIds.filter(id => applicationRoleId !== id);
              props.updateFormData({ ...props.formData, maeRoleIds });
              setNoMAERoles(!maeRoleIds.length);
            }}
          />
          {selectedTenant.tenantId === ASTM_TENANT_ID && (
            <Col xs={4} className="mt-3">
              <b className="astm-type-heading--h5-bold">Publishing Assistant Roles</b>
              {[{ applicationRoleId: 0, roleName: NO_PUB_ASSISTANT_ROLE }, ...pubAssistantRoles].map(({ applicationRoleId, roleName }) => (
                <div key={applicationRoleId} className="my-1">
                  <input
                    type="radio"
                    className="mr-2"
                    data-testid={`assistant-input-${applicationRoleId}`}
                    disabled={props.isReadOnly || (applicationRoleId === 8 && !hasAnyRole([AUTHORITIES.SUPER_ADMIN]))}
                    value={applicationRoleId}
                    onChange={() => props.updateFormData({ ...props.formData, pubAssistantRoleId: applicationRoleId })}
                    checked={props.formData.pubAssistantRoleId === applicationRoleId}
                  />
                  {roleName}
                </div>
              ))}
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default React.memo(UserRoles);
