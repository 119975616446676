import * as Types from './actionTypes';

export const GetStocks = () => ({
  type: Types.GET_STOCKS,
});

export const GetStocksInProgress = () => ({
  type: Types.GET_STOCKS_INPROGRESS,
});

export const GetStocksSuccess = (result: IStockModel[]) => ({
  type: Types.GET_STOCKS_SUCCESS,
  result,
});

export const GetStocksFailed = (error: string) => ({
  type: Types.GET_STOCKS_FAILED,
  meta: { errorMessage: error },
});

export const UpdateStock = (stock: IStockModel) => ({
  type: Types.UPDATE_STOCK,
  stock,
});

export const updateStockStatus = action => ({
  type: Types.UPDATE_STOCK_STATUS,
  action,
});

export const UpdateStockGrace = (stocks: IUpdatedStock[]) => ({
  type: Types.UPDATE_STOCK_GRACE,
  stocks,
});

export const UpdateStockStatusInprogress = () => ({
  type: Types.UPDATE_STOCK_STATUS_INPROGRESS,
});

export const UpdateStockSuccess = (stock: IStockModel) => ({
  type: Types.UPDATE_STOCK_SUCCESS,
  stock,
});

export const updateStockStatusSuccess = (successMessage: string) => ({
  type: Types.UPDATE_STOCK_STATUS_SUCCESS,
  meta: { successMessage },
});

export const updateStockStatusFailed = (errorMessage: string) => ({
  type: Types.UPDATE_STOCK_STATUS_FAILED,
  meta: {
    errorMessage,
  },
});

export const UpdateStockFailed = (error: string) => ({
  type: Types.UPDATE_STOCK_FAILED,
  meta: { errorMessage: error },
});

export const UpdateStockGraceSuccess = (message: string) => ({
  type: Types.UPDATE_STOCK_GRACE_SUCCESS,
  meta: { successMessage: message },
});

export const UpdateStockGraceFailed = (error: string) => ({
  type: Types.UPDATE_STOCK_GRACE_FAILED,
  meta: { errorMessage: error },
});

export const SaveStockUpdates = (stocks: IStockModel[]) => ({
  type: Types.SAVE_STOCK_UPDATES,
  stocks,
});

export const SaveStockUpdatesInprogress = () => ({
  type: Types.SAVE_STOCK_UPDATES_INPROGRESS,
});

export const SaveStockUpdatesSuccess = (stocks: IStockModel[]) => ({
  type: Types.SAVE_STOCK_UPDATES_SUCCESS,
  stocks,
});

export const SaveStockUpdatesFailed = (error: string) => ({
  type: Types.SAVE_STOCK_UPDATES_FAILED,
  meta: { errorMessage: error },
});

export const OnFilterAccessExpiry = (status: boolean) => ({
  type: Types.ON_FILTER_ACCESS_EXPIRY,
  status,
});

export const stockSelectionThresholdError = (errorMessage: string) => ({
  type: Types.STOCK_SELECTION_THRESHOLD_ERROR,
  meta: { errorMessage },
});

export const handleBulkGracePeriodSelection = (gracePeriod: ISelectOption) => ({
  type: Types.BULK_STOCK_GRACE_PERIOD_SELECTION,
  gracePeriod,
});
