import { all, fork } from 'redux-saga/effects';
import userAdministrationSaga from '../user-administration/data/saga';
import searchPageSaga from '../user-search/data/saga';
import settingsSaga from '../appsettings/data/saga';
import stocksSaga from '../stocks/data/saga';
import IPSaga from '../ip/data/saga';
import tenantsSaga from '../landing-home/data/saga';
import getRevisionHistorySaga from '../audit/data/saga';

export default function* pagesRootSaga() {
  yield all([
    fork(userAdministrationSaga),
    fork(searchPageSaga),
    fork(stocksSaga),
    fork(IPSaga),
    fork(settingsSaga),
    fork(tenantsSaga),
    fork(getRevisionHistorySaga),
  ]);
}
