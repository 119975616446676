export const GET_TENANTS = 'pages/landing-home/GET_TENANTS';
export const GET_TENANTS_IN_PROGRESS = 'pages/landing-home/GET_TENANTS_IN_PROGRESS';
export const GET_TENANTS_SUCCESS = 'pages/landing-home/GET_TENANTS_SUCCESS';
export const ON_TENANT_SELECTION = 'pages/landing-home/ON_TENANT_SELECTION';

export interface GetTenants {
  type: typeof GET_TENANTS;
}
export interface GetTenantsSuccess {
  type: typeof GET_TENANTS_SUCCESS;
  tenants: ITenantsModel[];
}

export interface OnTenantSelection {
  type: typeof ON_TENANT_SELECTION;
  tenant: ITenantsModel;
}

export interface OnGetTenantsInProgress {
  type: typeof GET_TENANTS_IN_PROGRESS;
  payload: boolean;
}

export type tenantsActionTypes = GetTenants | GetTenantsSuccess | OnTenantSelection | OnGetTenantsInProgress;
