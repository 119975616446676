import * as Types from './actionTypes';

export const getRevisionHistory = () => {
  return {
    type: Types.GET_REVISION_HISTORY,
  };
};

export const getRevisionHistorySuccess = data => {
  return {
    type: Types.GET_REVISION_HISTORY_SUCCESS,
    data: { revisionHistory: data.revisionHistory, accountNoteHistory: data.accountNoteHistory },
  };
};

export const getRevisionHistoryFailed = (errorMessage: string) => {
  return {
    type: Types.GET_REVISION_HISTORY_FAILED,
    meta: { errorMessage },
  };
};
