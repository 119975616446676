import { IAccountSetting } from '../accountTypes';

export const ON_UPDATE_ACCOUNT_SETTINGS = 'components/account-attributes/ON_UPDATE_ACCOUNT_SETTINGS';
export const ON_UPDATE_SUCCESS = 'components/account-attributes/ON_UPDATE_SUCCESS';
export const ON_UPDATE_FAILED = 'components/account-attributes/ON_SAVE_FAILED';
export const ON_UPDATE_INPROGRESS = 'components/account-attributes/ON_UPDATE_INPROGRESS';
export const ON_CREATE_ACCOUNT_NOTE = 'components/ON_CREATE_ACCOUNT_NOTE';

export interface OnUpdateAccountSettings {
  type: typeof ON_UPDATE_ACCOUNT_SETTINGS;
  accountId: number;
  accountSettingId: number;
  accountSetting: IAccountSetting;
  accountData: any;
  cbfn: Function;
  hasNote: string;
}

export interface OnUpdateSuccess {
  type: typeof ON_UPDATE_SUCCESS;
  meta: { successMessage: string };
}
export interface OnUpdateFailed {
  type: typeof ON_UPDATE_FAILED;
  meta: { errorMessage: string };
}

export interface OnUpdateInprogress {
  type: typeof ON_UPDATE_INPROGRESS;
}
