import { put, call, takeEvery, fork, select, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import * as API from 'app/modules/services/serverAPI';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { prepareIpSearchTableData, prepareTableData, prepareUserTableData } from './helper';
import * as tenantSelector from '../../landing-home/data/selector';

function* apiSelector(action) {
  const { tenantId } = yield select(tenantSelector.selectedTenant);
  switch (action.selections.item.value) {
    case 'Account Name':
      return yield call(
        API.searchAccountsByAccountName,
        tenantId,
        action.selections.value,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'Account Number':
      return yield call(
        API.searchAccountsByAccountNumber,
        tenantId,
        action.selections.value,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'Account Id':
      return yield call(
        API.searchAccountsByAccountId,
        tenantId,
        String(action.selections.value).toLowerCase(),
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'Account Id and Name':
      return yield call(
        API.searchAccountsByAccountIdAndAccountName,
        tenantId,
        String(action.selections.accountId).toLowerCase(),
        action.selections.accountName || undefined,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'Email or Username':
      return yield call(
        API.searchByEmailOrUsername,
        tenantId,
        action.selections.value,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'Account_Id_and_User_Id':
      return yield call(
        API.searchByAccountIdAndUserId,
        tenantId,
        action.selections.value,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    case 'IP Address':
      return yield call(API.searchByIpAddress, tenantId, action.selections.value);
    case 'Tenant Account Number':
      return yield call(
        API.searchAccountsByTenantAccNumber,
        tenantId,
        action.selections.value,
        action.selections?.pagination?.pageIndex,
        action.selections?.pagination?.pageSize
      );
    default:
      return;
  }
}

function* onSearchData(action: actionTypes.OnSearch | actionTypes.OnEmailOrUsernameSearch) {
  try {
    let result;
    yield put(actions.OnSearchInProgress());
    if (action.selections.item === null || action.selections.item.value === 'Select') {
      return yield put(actions.OnSearchFailed('Please select at least one search option'));
    } else if (isEmpty(action.selections.value)) {
      return yield put(actions.OnSearchFailed('Please enter search data'));
    }
    const response = yield call(apiSelector, action);

    if (action.selections.item.value === 'Email or Username' || action.selections.item.value === 'Account_Id_and_User_Id') {
      if (response.status === 204) {
        return yield put(actions.OnSearchFailed('No data found', response.status));
      }

      const data = [];
      response.data.responseData.content.forEach(_data => {
        _data.accountUser &&
          _data.accountUser.forEach((item: any) => {
            data.push({ ...item, ..._data.user, _data });
          });
      });

      result = yield call(prepareUserTableData, data);
      result.totalElements = response.data.responseData.totalElements;
    } else if (action.selections.item.value === 'IP Address') {
      if (response.status === 200 && response.data && !isEmpty(response.data.responseData.value)) {
        result = yield call(prepareIpSearchTableData, [response.data.responseData.value]);
      } else if (response.status === 400) {
        if (response.data.responseData && response.data.responseData.message) {
          return yield put(actions.OnSearchFailed(response.data.responseData.message));
        }
        const message = JSON.parse(response.data.message);
        if (message && message[0] && message[0].message) {
          return yield put(actions.OnSearchFailed(message[0].message));
        }
        return yield put(actions.OnSearchFailed('No data found'));
      } else {
        return yield put(actions.OnSearchFailed('No data found'));
      }
    } else if (response.status === 200 && response.data) {
      result = yield call(prepareTableData, response.data.responseData.content);
      result.totalElements = response.data.responseData.totalElements;
    } else if (response.status === 204) {
      return yield put(actions.OnSearchFailed('No data found', response.status));
    } else if (response.status === 401) {
      return yield put(actions.OnSearchFailed('unauthorized', response.status));
    } else if (response.status === 400) {
      return yield put(actions.OnSearchFailed('Bad Request. Cannot construct instance', response.status));
    } else {
      return yield put(actions.OnSearchFailed('Unexpected error occured, please try again', response.status));
    }
    return yield put(actions.OnSearchSuccess(result, response.status));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error : ', error);

    return yield put(actions.OnSearchFailed('Unexpected error occured, please try again', 404));
  }
}

function* onPopulateAccountById(action: actionTypes.OnPopulateAccountById) {
  try {
    yield put(actions.OnPopulateAccountInProgress());
    if (action.type) {
      const response = yield call(apiSelector, action);
      if (response.status === 200 && response.data && response.data.responseData.content && response.data.responseData.content.length) {
        yield put(actions.OnUserAccountSelected(response.data.responseData.content[0]));
        return yield put(actions.OnPopulateAccountSuccess());
      } else {
        return yield put(actions.OnPopulateAccountFailed('Error occured, Please try again'));
      }
    }
  } catch (error) {
    return yield put(actions.OnSearchFailed('Unexpected error occured, please try again'));
  }
}

function* onUserAccountSelected(action: actionTypes.OnUserAccountSelected) {
  if (action.options.refetch) {
    const apiAction: any = {};

    const accountNumber =
      (action.payload.account && action.payload.account.accountNumber) ||
      (action.payload.orgAccountDetails && action.payload.orgAccountDetails.accountNumber);

    const accountId = action.payload.account && action.payload.account.accountId;
    const accountName = action.payload.account && action.payload.account.name;

    if (accountNumber) {
      apiAction.selections = {
        item: {
          value: 'Account Number',
        },
        value: accountNumber,
      };
    } else if (accountId) {
      apiAction.selections = {
        item: {
          value: 'Account Id and Name',
        },
        accountId,
        accountName,
      };
    }

    if (apiAction.selections) {
      const response = yield call(apiSelector, apiAction);
      if (response.status && response.data.responseData.content && response.data.responseData.content.length) {
        return yield put(actions.OnUserAccountSelected(response.data.responseData.content[0]));
      }
    }
  }
}

function* watchSearchPage() {
  yield takeEvery([actionTypes.ON_SEARCH, actionTypes.ON_SEARCH_EMAIL_USERNAME], onSearchData);
  yield takeLatest(actionTypes.ON_POPULATE_ACCOUNT_BY_ID, onPopulateAccountById);
  yield takeLatest(actionTypes.ON_USER_ACCOUNT_SELECTED, onUserAccountSelected);
}

export default function* searchPageSaga() {
  yield fork(watchSearchPage);
}
