import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as API from 'app/modules/services/serverAPI';
import { IRootState } from 'app/shared/reducers';
import { ACCOUNT_TYPES } from 'app/config/constants';
import { getRedirectURL } from 'app/shared/util/common-utils';
import { PayloadAction } from 'app/shared/reducers/action-type.util';
import {
  NO_COMPASS_ROLE,
  NO_PUBLIC_ROLE,
  NO_SPECBUILDER_ROLE,
  NO_MAE_ROLE,
  NO_MEMBER_ROLE,
  NO_CONTENT_VIEWER_ROLE,
  NO_PUB_ASSISTANT_ROLE,
} from 'app/modules/pages/user-administration/user-modal/user-account-data/user-roles/userRoles';
import { actions } from './reducer';

export function* callSuccess(successMessage: string) {
  yield put(actions.setResult({ meta: { successMessage } }));
}

export function* callError(errorMessage: string) {
  yield put(actions.setResult({ meta: { errorMessage } }));
}

const getRolesName = (ids: number[], roles: any[]) =>
  ids
    .map(id => {
      const role = roles.find(r => r.applicationRoleId === id);
      return role.roleName;
    })
    .join(', ');

export function* getCompassRolesName(ids: number[]) {
  const compassRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.compassRoles);
  return getRolesName(ids, compassRoles) || NO_COMPASS_ROLE;
}

export function* getSpecBuilderRoleName(id: number) {
  const specBuilderRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.specBuilderRoles);
  return specBuilderRoles.find(role => role.applicationRoleId === id)?.roleName || NO_SPECBUILDER_ROLE;
}

export function* getPubAssistantRoleName(id: number) {
  const pubAssistantRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.pubAssistantRoles);
  return pubAssistantRoles.find(role => role.applicationRoleId === id)?.roleName || NO_PUB_ASSISTANT_ROLE;
}

export function* getMemberRoleName(id: number) {
  const memberRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.memberRoles);
  return memberRoles.find(role => role.applicationRoleId === id)?.roleName || NO_MEMBER_ROLE;
}

export function* getContentViewerRoleName(id: number) {
  const contentViewerRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.contentViewerRoles);
  return contentViewerRoles.find(role => role.applicationRoleId === id)?.roleName || NO_CONTENT_VIEWER_ROLE;
}

export function* getPublicRolesName(ids: number[]) {
  const publicRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.publicRoles);
  return getRolesName(ids, publicRoles) || NO_PUBLIC_ROLE;
}

export function* getMAERolesName(ids: number[]) {
  const maeRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.maeRoles);
  return getRolesName(ids, maeRoles) || NO_MAE_ROLE;
}

export function* fetchCompassRoles() {
  const compassRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.compassRoles);
  if (compassRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 1);
  if (response.status === 200) {
    yield put(actions.fetchCompassRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchSpecBuilderRoles() {
  const specBuilderRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.specBuilderRoles);
  if (specBuilderRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 2);
  if (response.status === 200) {
    yield put(actions.fetchSpecbuilderRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchPubAssistantRoles() {
  const pubAssistantRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.pubAssistantRoles);
  if (pubAssistantRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 9);
  if (response.status === 200) {
    yield put(actions.fetchPubAssistantRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchMemberRoles() {
  const memberRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.memberRoles);
  if (memberRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 4);
  if (response.status === 200) {
    yield put(actions.fetchMemberRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchContentViewerRoles() {
  const contentViewerRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.contentViewerRoles);
  if (contentViewerRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 7);
  if (response.status === 200) {
    yield put(actions.fetchContentViewerRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchPublicRoles() {
  const publicRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.publicRoles);
  if (publicRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 3);
  if (response.status === 200) {
    yield put(actions.fetchPublicRolesSuccess(response.data.responseData || []));
  }
}

export function* fetchMAERoles() {
  const maeRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.maeRoles);
  if (maeRoles) return;
  const response = yield call(API.fetchRolesOfUserRegistration, 5);
  if (response.status === 200) {
    yield put(actions.fetchMAERolesSuccess(response.data.responseData || []));
  }
}

export function* fetchAccountLocation(action: PayloadAction<number>) {
  const accountLocations = yield select((state: IRootState) => state.entities.pages.userAdministration.accountLocations);
  if (accountLocations) return;
  const response = yield call(API.fetchAccountLocation, action.payload);
  if (response.data.responseData?.code === '200') {
    yield put(actions.fetchAccountLocationSuccess(response.data.responseData.value || []));
  }
}

export function* fetchAccountDivision(action: PayloadAction<number>) {
  const accountDivisions = yield select((state: IRootState) => state.entities.pages.userAdministration.accountDivisions);
  if (accountDivisions) return;
  const response = yield call(API.fetchAccountDivision, action.payload);
  if (response.data.responseData?.code === '200') {
    yield put(actions.fetchAccountDivisionSuccess(response.data.responseData.value || []));
  }
}

export function* fetchAccountSettings(action: PayloadAction<number>) {
  if (!action.payload) return;
  const response = yield call(API.getApplicationSettings, action.payload);
  if (response.data.responseData?.code === '200') {
    yield put(actions.fetchAccountSettingsSuccess(response.data.responseData.value || []));
  }
}

export function* addApplicationRole(applicationRoleId: number, accountUserId: number, applicationId: number) {
  if (applicationRoleId) {
    const innerResp = yield call(API.addApplicationRole, {
      applicationRoleId,
      accountUserId,
      applicationId,
    });
    if (innerResp.status === 400) {
      throw innerResp.data.message || innerResp.data.responseData.message;
    }
  }
}

export function* removeApplicationRole(accountApplicationUserRoles: any, applicationRoleId: number) {
  const toRemove = accountApplicationUserRoles.find(role => role.applicationRoleId === applicationRoleId);
  if (toRemove?.accountApplicationUserRoleId) {
    const innerResp = yield call(API.removeApplicationRole, toRemove.accountApplicationUserRoleId);
    if (innerResp.status === 400) {
      throw innerResp.data.responseData.message;
    }
  }
}

// eslint-disable-next-line complexity
export function* saveUser(action) {
  try {
    const user = action.payload;
    const selectedUser = yield select((state: IRootState) => state.entities.pages.searchPage.selectedUser);
    const tenant = yield select((state: IRootState) => state.entities.pages.tenantSelectionPage.selectedTenant);
    const compassRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.compassRoles);
    const specBuilderRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.specBuilderRoles);
    const pubAssistantRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.pubAssistantRoles);
    const memberRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.memberRoles || []);
    const contentViewerRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.contentViewerRoles || []);
    const publicRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.publicRoles);
    const maeRoles = yield select((state: IRootState) => state.entities.pages.userAdministration.maeRoles);
    const specBuilderRole = specBuilderRoles && specBuilderRoles.find(role => role.applicationRoleId === user.specBuilderRoleId);
    const pubAssistantRole = pubAssistantRoles && pubAssistantRoles.find(role => role.applicationRoleId === user.pubAssistantRoleId);
    const newMemberRole = memberRoles.find(role => role.applicationRoleId === user.memberRoleId);
    const newContentViewerRole = contentViewerRoles.find(role => role.applicationRoleId === user.contentViewerRoleId);

    const newCompassRoles =
      (compassRoles &&
        compassRoles
          .filter(role => user.compassRoleIds.includes(role.applicationRoleId))
          .map(role => ({ applicationCode: 'COMPASS', applicationRoleCode: role.roleCode }))) ||
      [];

    const newPublicRoles =
      (publicRoles &&
        publicRoles
          .filter(role => user.publicRoleIds.includes(role.applicationRoleId))
          .map(role => ({ applicationCode: 'PUB', applicationRoleCode: role.roleCode }))) ||
      [];
    const newMaeRoles =
      (maeRoles &&
        maeRoles
          .filter(role => user.maeRoleIds.includes(role.applicationRoleId))
          .map(role => ({ applicationCode: 'MAE', applicationRoleCode: role.roleCode }))) ||
      [];

    const applicationUserRoles = [...newCompassRoles, ...newPublicRoles, ...newMaeRoles];
    if (specBuilderRole) applicationUserRoles.push({ applicationCode: 'SB', applicationRoleCode: specBuilderRole.roleCode });
    if (pubAssistantRole) applicationUserRoles.push({ applicationCode: 'PUBASSISTANT', applicationRoleCode: pubAssistantRole.roleCode });
    if (newMemberRole) applicationUserRoles.push({ applicationCode: 'MEMBER', applicationRoleCode: newMemberRole.roleCode });
    if (newContentViewerRole)
      applicationUserRoles.push({ applicationCode: 'CONTENTVIEWER', applicationRoleCode: newContentViewerRole.roleCode });
    const tenantRedirectURL = yield call(API.getTenantRedirectURL, ACCOUNT_TYPES[selectedUser?.account?.accountTypeId], tenant.tenantCode);

    const userName = user.email;
    const accountId = selectedUser.account.accountId;
    const userObj = {
      accountId,
      createIamUser: !user.presentInAnotherAccouresponsent,
      errorIfUserExists: !user.presentInAnotherAccount,
      sendActivationEmail: !user.presentInAnotherAccount,
      iamId: user.presentInAnotherAccount ? user.iamId : null,
      tenantId: tenant.tenantId,
      activationEmail: !user.presentInAnotherAccount
        ? {
            templateData: {
              redirectUrl: getRedirectURL(
                newCompassRoles,
                specBuilderRole?.applicationRoleId,
                selectedUser?.account?.accountTypeId,
                tenant.tenantId,
                tenantRedirectURL
              ),
            },
          }
        : null,
      user: {
        accountDivisionId: user.accountDivisionId,
        accountLocationId: user.accountLocationId,
        accountUserData: { additionalOrganization: user.additionalOrg || null },
        active: user.active,
        email: user.email,
        userName,
        firstName: user.firstName,
        middleName: user.middleName || null,
        lastName: user.lastName,
        nickName: user.nickName || null,
        apiOnly: false,
        orgUser: false,
      },
      applicationUserRoles,
    };
    const response = yield call(API.createUser, userObj);
    if (response.data.status === '200') {
      if (response.data.responseData[userName] && response.data.responseData[userName].code === '200') {
        let message = `${user.firstName} ${user.lastName} has been added to account. An${
          user.presentInAnotherAccount ? '' : ' activation'
        } email has been sent to ${user.email}.`;
        let roles = '';
        if (user.compassRoleIds.length) roles = yield call(getCompassRolesName, user.compassRoleIds);
        if (roles && user.publicRoleIds.length) roles += ', ';
        if (user.publicRoleIds.length) roles += yield call(getPublicRolesName, user.publicRoleIds);
        if (roles && user.maeRoleIds.length) roles += ', ';
        if (user.maeRoleIds.length) roles += yield call(getMAERolesName, user.maeRoleIds);
        if (roles && user.specBuilderRoleId) roles += ', ';
        if (user.specBuilderRoleId) roles += yield call(getSpecBuilderRoleName, user.specBuilderRoleId);
        if (roles && user.pubAssistantRoleId) roles += ', ';
        if (user.pubAssistantRoleId) roles += yield call(getPubAssistantRoleName, user.pubAssistantRoleId);
        if (roles && user.memberRoleId) roles += ', ';
        if (user.memberRoleId) roles += yield call(getMemberRoleName, user.memberRoleId);
        if (roles && user.contentViewerRoleId) roles += ', ';
        if (user.contentViewerRoleId) roles += yield call(getContentViewerRoleName, user.contentViewerRoleId);
        if (roles)
          message = `User has been added to account ${accountId} with the following roles: ${roles}. An activation email has been sent to ${user.email}.`;
        yield call(callSuccess, message);
      } else if (response.data.responseData[userName]) {
        yield call(callError, response.data.responseData[userName].message);
      }
    } else if (response.status === 400) {
      yield call(callError, response.data.responseData.message);
    } else if (response.status === 403) {
      yield call(callError, 'Access Denied!!');
    }
  } catch (ex) {
    yield call(callError, ex);
  }
}

export function* updateUserDetails(action) {
  try {
    const user = action.payload;
    const userObj = {
      active: user.active.toString(),
      email: user.email,
      firstName: user.firstName,
      middleName: user.middleName || null,
      lastName: user.lastName,
      nickName: user.nickName || null,
    };
    const accountUserObj = {
      accountDivisionId: user.accountDivisionId,
      accountLocationId: user.accountLocationId,
      data: { additionalOrganization: user.additionalOrg || null },
      active: user.accountUserActive,
    };
    const response = yield call(API.updateUser, user.userId, userObj);

    if (response.status !== 200 && response.data) {
      if (response.data.message || response.data.responseData.message) {
        return yield call(callError, response.data.message || response.data.responseData.message);
      }
    }

    const response2 = yield call(API.updateAccountUser, user.accountUserId, accountUserObj);
    if (response.data.status === '200' && response2.data.status === '200') yield call(callSuccess, 'User details updated.');
  } catch (ex) {
    yield call(callError, ex);
  }
}

export function* updateUser(action) {
  try {
    const {
      compassRoleIds,
      publicRoleIds,
      maeRoleIds,
      specBuilderRoleId,
      pubAssistantRoleId,
      memberRoleId,
      contentViewerRoleId,
      originalCompassRoleIds,
      originalSpecBuilderRoleId,
      originalPubAssistantRoleId,
      originalMemberRoleId,
      originalContentViewerRoleId,
      originalPublicRoleIds,
      originalMaeRoleIds,
      accountApplicationUserRoles,
      accountUserId,
    } = action.payload;

    const compassRolesToAdd = compassRoleIds.filter(id => !originalCompassRoleIds.some(prevRole => prevRole === id));
    const compassRolesToRemove = originalCompassRoleIds.filter(prevRole => !compassRoleIds.some(id => id === prevRole));

    let message = '';
    if (compassRolesToAdd.length || compassRolesToRemove.length) {
      for (const element of compassRolesToAdd) {
        yield call(addApplicationRole, element, accountUserId, 1);
      }

      for (const element of compassRolesToRemove) {
        yield call(removeApplicationRole, accountApplicationUserRoles, element);
      }

      message = yield call(getCompassRolesName, originalCompassRoleIds);
      message += ' to ';
      message += yield call(getCompassRolesName, compassRoleIds);
    }

    if (specBuilderRoleId !== originalSpecBuilderRoleId) {
      yield call(addApplicationRole, specBuilderRoleId, accountUserId, 2);
      yield call(removeApplicationRole, accountApplicationUserRoles, originalSpecBuilderRoleId);

      if (message) message += ' and ';
      message += yield call(getSpecBuilderRoleName, originalSpecBuilderRoleId);
      message += ' to ';
      message += yield call(getSpecBuilderRoleName, specBuilderRoleId);
    }

    if (pubAssistantRoleId !== originalPubAssistantRoleId) {
      yield call(addApplicationRole, pubAssistantRoleId, accountUserId, 2);
      yield call(removeApplicationRole, accountApplicationUserRoles, originalPubAssistantRoleId);

      if (message) message += ' and ';
      message += yield call(getPubAssistantRoleName, originalPubAssistantRoleId);
      message += ' to ';
      message += yield call(getPubAssistantRoleName, pubAssistantRoleId);
    }

    if (memberRoleId !== originalMemberRoleId) {
      yield call(addApplicationRole, memberRoleId, accountUserId, 4);
      yield call(removeApplicationRole, accountApplicationUserRoles, originalMemberRoleId);

      if (message) message += ' and ';
      message += yield call(getMemberRoleName, originalMemberRoleId);
      message += ' to ';
      message += yield call(getMemberRoleName, memberRoleId);
    }

    if (contentViewerRoleId !== originalContentViewerRoleId) {
      yield call(addApplicationRole, contentViewerRoleId, accountUserId, 7);
      yield call(removeApplicationRole, accountApplicationUserRoles, originalContentViewerRoleId);

      if (message) message += ' and ';
      message += yield call(getContentViewerRoleName, originalContentViewerRoleId);
      message += ' to ';
      message += yield call(getContentViewerRoleName, contentViewerRoleId);
    }

    const publicRolesToAdd = publicRoleIds.filter(id => !originalPublicRoleIds.some(prevRole => prevRole === id));
    const publicRolesToRemove = originalPublicRoleIds.filter(prevRole => !publicRoleIds.some(id => prevRole === id));

    if (publicRolesToAdd.length || publicRolesToRemove.length) {
      for (const element of publicRolesToAdd) {
        yield call(addApplicationRole, element, accountUserId, 3);
      }

      for (let i = 0; i < publicRolesToRemove.length; i++) {
        const element = publicRolesToRemove[i];
        yield call(removeApplicationRole, accountApplicationUserRoles, element);
      }

      if (message) message += ' and ';
      message += yield call(getPublicRolesName, originalPublicRoleIds);
      message += ' to ';
      message += yield call(getPublicRolesName, publicRoleIds);
    }

    const maeRolesToAdd = maeRoleIds.filter(id => !originalMaeRoleIds.some(prevRole => prevRole === id));
    const maeRolesToRemove = originalMaeRoleIds.filter(prevRole => !maeRoleIds.some(id => prevRole === id));

    if (maeRolesToAdd.length || maeRolesToRemove.length) {
      for (const element of maeRolesToAdd) {
        yield call(addApplicationRole, element, accountUserId, 5);
      }

      for (let i = 0; i < maeRolesToRemove.length; i++) {
        const element = maeRolesToRemove[i];
        yield call(removeApplicationRole, accountApplicationUserRoles, element);
      }

      if (message) message += ' and ';
      message += yield call(getMAERolesName, originalMaeRoleIds);
      message += ' to ';
      message += yield call(getMAERolesName, maeRoleIds);
    }

    if (message) yield call(callSuccess, `User's roles for account ${accountUserId} have been updated from ${message}`);
  } catch (error) {
    yield call(callError, error);
  }
}

export function* resendActivationEmail(action) {
  const response = yield call(API.registerNewUser, action.payload);

  if (response.status === 200) {
    return yield call(callSuccess, 'Account Activation Email Sent');
  }

  yield call(callError, 'Account has been previously activated. No activation email has been sent.');
}

export default function* RegisterUserSaga() {
  yield takeLatest(actions.fetchCompassRoles.type, fetchCompassRoles);
  yield takeLatest(actions.fetchSpecbuilderRoles.type, fetchSpecBuilderRoles);
  yield takeLatest(actions.fetchPubAssistantRoles.type, fetchPubAssistantRoles);
  yield takeLatest(actions.fetchMemberRoles.type, fetchMemberRoles);
  yield takeLatest(actions.fetchContentViewerRoles.type, fetchContentViewerRoles);
  yield takeLatest(actions.fetchPublicRoles.type, fetchPublicRoles);
  yield takeLatest(actions.fetchMAERoles.type, fetchMAERoles);
  yield takeLatest(actions.fetchAccountLocation.type, fetchAccountLocation);
  yield takeLatest(actions.fetchAccountDivision.type, fetchAccountDivision);
  yield takeLatest(actions.fetchAccountSettings.type, fetchAccountSettings);
  yield takeLatest(actions.saveUser.type, saveUser);
  yield takeLatest(actions.updateUserDetails.type, updateUserDetails);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.resendActivationEmail.type, resendActivationEmail);
}
