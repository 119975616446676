/* eslint-disable radix */
import React from 'react';
import { Item } from './models';
import orderBy from 'lodash/orderBy';

interface ListBoxProps {
  isShowSelectAll?: boolean;
  items: Item[];
  isAllSelected?: boolean;
  onItemClick?: (item: Item) => any;
  header: string;
  handleChange?: (value: Item[]) => void;
}

export class ListBox extends React.Component<ListBoxProps> {
  state = {
    selections: [],
  };

  render() {
    return (
      <div className="form-group">
        <div className="row">
          <label htmlFor="selectFrom" className="col-md-8">
            {this.props.header}
          </label>
          {this.props.isShowSelectAll && this.props.items.length > 0 ? (
            <label htmlFor="selectFrom" id="lbl-selectall" className="col-md-4 text-secondary" onClick={() => this.onSelectAll()}>
              Select All
            </label>
          ) : null}
        </div>
        <div className="row">
          <select
            name="selectFrom"
            id="selectFrom"
            className="form-control input-medium"
            size={7}
            multiple={true}
            onChange={event => this.handleChange(event)}
            value={this.state.selections}
          >
            {this.getOrderedItems().map(item => {
              return (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  getOrderedItems = () => {
    return orderBy(this.props.items, 'value', 'asc');
  };

  onSelectAll = () => {
    const res = this.props.items.map(i => i.value);
    this.setState({ selections: res });
    return this.props.handleChange(this.props.items);
  };

  handleChange = e => {
    const options = e.target.options;
    const selections: Item[] = [];
    const selectedValues = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedValues.push(parseInt(options[i].value));
        const res = this.props.items.find(j => j.value === parseInt(options[i].value));
        selections.push(res);
      }
    }
    this.setState({ selections: selectedValues });
    return this.props.handleChange(selections);
  };
}
