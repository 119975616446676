import { combineReducers } from 'redux';
import ipGenerator, { IIpGeneratorState } from '../ipGenerator/data/reducers';

export interface IComponentsState {
  readonly ipGenerator: IIpGeneratorState;
}

const components = combineReducers<IComponentsState>({
  ipGenerator,
});

export default components;
