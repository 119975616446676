import { combineReducers } from 'redux';
import { reducer as properties, IPropertiesState } from './properties.reducer';
import sidebar, { ISidebarState } from './sidebar.reducer';
import notificationReducer, { INotificationState } from './notification.reducer';
import entities, { IEntitiesState } from 'app/modules/store/reducer';
import { reducer as jwtLoginReducer, IJWTLoginState } from 'app/modules/components/userHeader/jwtLoginSlice';
import { reducer as baseUserReducer, IBaseUserState } from 'app/shared/reducers/base-user.reducer';
import {
  reducer as identityDelegationReducer,
  IIdentityDelegationState,
} from 'app/modules/pages/identityDelegation/IdentityDelegationSlice';

export interface IRootState {
  readonly properties: IPropertiesState;
  readonly sidebar: ISidebarState;
  readonly notificationReducer: INotificationState;
  readonly entities: IEntitiesState;
  readonly jwtLogin: IJWTLoginState;
  readonly identityDelegation: IIdentityDelegationState;
  readonly baseUser: IBaseUserState;
}

const rootReducer = combineReducers<IRootState>({
  properties,
  sidebar,
  notificationReducer,
  entities,
  jwtLogin: jwtLoginReducer,
  baseUser: baseUserReducer,
  identityDelegation: identityDelegationReducer,
});

export default rootReducer;
