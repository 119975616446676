import { Storage } from 'react-jhipster';

export const getOktaUser = () => {
  const tokenStorage = Storage.session.get('okta-token-storage');
  return tokenStorage?.idToken?.claims;
};

const getUserRoles = () => {
  const oktaRoles = getOktaUser()?.roles || [];
  const maeRoles = Storage.session.get('MAERoles') || [];
  return [...oktaRoles, ...maeRoles];
};

export const hasAnyRole = (roles: string[] = []) => getUserRoles().some(role => roles.includes(role));
