import { createSlice } from '@reduxjs/toolkit';

export interface ISidebarState {
  showSidebar: boolean | null;
  displaySidebar: boolean;
  errorMessage: string | null;
  updateSuccess: boolean;
  loading: boolean;
}

const initialState: ISidebarState = {
  showSidebar: null,
  displaySidebar: false,
  errorMessage: null,
  updateSuccess: false,
  loading: true,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    showSidebar(state) {
      state.showSidebar = true;
      state.displaySidebar = true;
    },
    hideSidebar(state) {
      state.showSidebar = false;
    },
    // Add other reducers as needed
  },
});

export const { showSidebar, hideSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
