const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  SUPER_ADMIN: 'PLATFORM_SUPER_ADMIN',
  READONLY_ADMIN: 'PLATFORM_READ_ONLY_ADMIN',
  E_COMMERCE_ADMIN: 'ECOMMERCE_ADMIN',
  MAE_ACCOUNT_ADMIN: 'MAE_ACCOUNT_ADMIN',
  MAE_USER_ADMIN: 'MAE_USER_ADMIN',
  PIM_SUPER_ADMIN: 'PIM_SUPER_ADMIN',
  PIM_READ_ONLY_ADMIN: 'PIM_READ_ONLY_ADMIN',
  TIMS_READ_ONLY_ADMIN: 'TIMS_READ_ONLY_ADMIN',
  TIMS_SUPER_ADMIN: 'TIMS_SUPER_ADMIN',
  TIMS_ADMIN: 'TIMS_ADMIN',
  IDENTITY_DELEGATION_ADMIN_MEMBER_WRITE: 'IDENTITY_DELEGATION_ADMIN_MEMBER_WRITE',
  IDENTITY_DELEGATION_ADMIN_MEMBER_READONLY: 'IDENTITY_DELEGATION_ADMIN_MEMBER_READONLY',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const oktaConfig = {
  ASTM: {
    clientId: process.env.CLIENT_ID_ASTM,
    issuer: process.env.ISSUER_ASTM,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  API: {
    clientId: process.env.CLIENT_ID_API,
    issuer: process.env.ISSUER_API,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

export const LOGIN_PAGE_URL = { ASTM: process.env.LOGIN_PAGE_URL_ASTM, API: process.env.LOGIN_PAGE_URL_API };
export const IMPERSONATION_ROLES = [
  AUTHORITIES.IDENTITY_DELEGATION_ADMIN_MEMBER_READONLY,
  AUTHORITIES.IDENTITY_DELEGATION_ADMIN_MEMBER_WRITE,
];

export const USER_TYPE = {
  INDIVIDUAL: 'subscriber individual',
  COMPANY: 'subscriber company',
};

export const AUTH_STATUS = {
  AUTH_VERIFICATION: 'AUTH_VERIFICATION',
  SUSPENDED: 'SUSPENDED',
};

export const QUICKSIGHT_API_URL = process.env.QUICKSIGHT_API_URL
  ? process.env.QUICKSIGHT_API_URL
  : 'https://mxoy6gpx1g.execute-api.us-east-2.amazonaws.com/V1/GetDashboardEmbedUrl?dashboardId=';

export const QUICKSIGHT_USAGE_REPORT_ID = process.env.QUICKSIGHT_REPORT_ID
  ? process.env.QUICKSIGHT_REPORT_ID
  : 'Usage-Report-B2B-Reader-Dashboard-QA';

export const QUICKSIGHT_WL_USAGE_REPORT_ID =
  process.env.QUICKSIGHT_WL_USAGE_REPORT_ID && process.env.QUICKSIGHT_WL_USAGE_REPORT_ID.startsWith('Usage-Report')
    ? process.env.QUICKSIGHT_WL_USAGE_REPORT_ID
    : 'Usage-Report-WL-Tenant-Admin-Dashboard-QA';

export const ACCOUNT_TYPES = {
  1: 'ORGANIZATION',
  2: 'PERSON',
};

export const ASTM_TENANT_ID = 1;

export const MAE_PE_STOCK_SELECTION_THRESHOLD = process.env.MAE_PE_STOCK_SELECTION_THRESHOLD;

export const CALLBACK_PATH = '/implicit/callback';

export const TENANT_LIST = {
  ASTM: process.env.TENANT_URL_ASTM && !process.env.TENANT_URL_ASTM.startsWith('#') ? process.env.TENANT_URL_ASTM : 'astm.org', // manage.astm.org
  API: process.env.TENANT_URL_API && !process.env.TENANT_URL_API.startsWith('#') ? process.env.TENANT_URL_API : 'astmapi.site', // 'manage.astmapi.site',
};

export const updateNoteErrorMsg = 'Update saved successfully. Failed to add note.';

export const B2B_DEFAULT_REDIRECT_URL = process.env.B2B_DEFAULT_REDIRECT_URL;

export const TENANT_STATIC_WEB_RESOURCES_ROOT_URL = process.env.TENANT_STATIC_WEB_RESOURCES_ROOT_URL
  ? process.env.TENANT_STATIC_WEB_RESOURCES_ROOT_URL
  : 'https://astm-tenant-web-resources.s3.us-west-1.amazonaws.com';

// The below code has to be a string and not boolean.
// This is because of limitations of ENV file. It can only store string values.
export const ENABLE_DYNAMIC_WL_TENANT_STYLING = process.env.ENABLE_DYNAMIC_WL_TENANT_STYLING || 'FALSE';

export const REACT_APP_ENABLE_DYNAMIC_FOOTER = process.env.REACT_APP_ENABLE_DYNAMIC_FOOTER || 'FALSE';
export const REACT_APP_ASTM_DISPLAY_ADDRESS = process.env.REACT_APP_ASTM_DISPLAY_ADDRESS || '';

export const staticFooter = {
  ASTM: {
    socials: {
      links: [
        {
          url: 'https://twitter.com/ASTMIntl',
          label: 'Twitter',
          'fa-icon': 'footer-twitter',
        },
        {
          url: 'https://www.facebook.com/pages/ASTM-International/124658656513',
          label: 'Facebook',
          'fa-icon': 'footer-facebook',
        },
        {
          url: 'https://www.youtube.com/ASTMIntl ',
          label: 'Youtube',
          'fa-icon': 'footer-youtube-square',
        },
        {
          url: 'https://www.linkedin.com/company/astm-international?trk=cp_followed_name_astm-international',
          label: 'LinkedIn',
          'fa-icon': 'footer-linkedIn',
        },
        {
          url: 'https://www.instagram.com/astmintl/',
          label: 'Instagram',
          'fa-icon': 'footer-instagram',
        },
        {
          url: 'https://www.astm.org/RSS/index.html',
          label: 'RSS',
          'fa-icon': 'footer-rss',
        },
      ],
    },
    links: [
      {
        url: 'https://www.astm.org',
        label: 'Home',
      },
      {
        url: 'https://www.astm.org/ABOUT/overview.html',
        label: 'About ASTM',
      },
      {
        url: 'https://www.astm.org/contact',
        label: 'Contact',
      },
      {
        url: 'https://www.astm.org/privacy-policy',
        label: 'Private Policy',
      },
      {
        url: 'https://www.astm.org/policies.html',
        label: 'Policies',
      },
      {
        url: 'https://www.astm.org/copyright-and-permissions',
        label: 'Copyright/Permissions',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress: 'ASTM International, 100 Barr Harbor Drive, PO Box C700, West Conshohocken, PA, 19428-2959 USA',
  },
  API: {
    socials: null,
    links: [
      {
        url: 'https://www.apicompass.org',
        label: 'Home',
      },
      {
        url: 'https://www.astm.org/api-wl-contact.html',
        label: 'Contact',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress: 'American Petroleum Institute 1220 L Street, NW, Washington, DC 20005-4070 USA',
  },
  cga: {
    socials: null,
    links: [
      {
        url: 'https://compass.cganet.com',
        label: 'Home',
      },
      {
        url: 'https://compass.cganet.com/CUSTOMERS/contact.html',
        label: 'Contact',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress: 'Compressed Gas Association, 8484 Westpark Drive, Suite 220, McLean, VA 22102',
  },
  ies: {
    socials: null,
    links: [
      {
        url: 'https://lightinglibrary.ies.org',
        label: 'Home',
      },
      {
        url: 'https://lightinglibrary.ies.org/CUSTOMERS/contact.html',
        label: 'Contact',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress: 'Illuminating Engineering Society 120 Wall Street, New York, NY 10005-4001, USA',
  },
  awwa: {
    socials: null,
    links: [
      {
        url: 'https://envoi.awwa.org',
        label: 'Home',
      },
      {
        url: 'https://envoi.awwa.org/CUSTOMERS/contact.html',
        label: 'Contact',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress:
      'American Water Works Association 6666 W. Quincy Ave., Denver, CO 80235 USA/Phone: 303.794.7711 or 800.926.7337/Fax: 303.347.0804',
  },
  aenor: {
    socials: null,
    links: [
      {
        url: 'https://aenormaspremium.aenor.com',
        label: 'Home',
      },
      {
        url: 'https://aenormaspremium.aenor.com/CUSTOMERS/contact.html',
        label: 'Contact',
      },
      {
        url: 'blank',
        label: 'Support',
      },
    ],
    displayAddress: 'AENOR C/ Génova, 6. 28004 Madrid. | Tel. (+34) 914 326 032 / 127',
  },
};

export const PIM_UI_URL = process.env.PIM_UI_URL;

// Tims UI URL to navigate the User to TIMS App. Will change for each env.
export const TIMS_UI_URL = process.env.TIMS_UI_URL;
