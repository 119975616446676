import { put, call, takeEvery, fork, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as compassActions from '../../compass/data/actions';
import * as compassActionTypes from '../../compass/data/actionTypes';
import * as selectors from '../../ip/data/selector';
import * as compassSelectors from '../../compass/data/selector';
import * as serverAPI from 'app/modules/services/serverAPI';
import { getUpdateNotePayloadMap } from 'app/shared/util/common-utils';
import { updateNoteErrorMsg } from 'app/config/constants';

const excludeProps = [
  'createdById',
  'createdDate',
  'modifiedById',
  'modifiedDate',
  'applicationId',
  'accountAuthenticationSettingId',
  'accountApplicationAuthenticationSettingId',
];

function* SaveCompassSettings(action: actionTypes.SaveCompassSettings) {
  try {
    let response;
    const settings = Object.assign({}, action.settings);

    settings.status = JSON.stringify(settings.status);

    const newSubscriptionSettings = { ...action.subscriptionSettings };
    const settingId = action.settings.accountApplicationAuthenticationSettingId;
    const subscriptionSettings = yield select(compassSelectors.subscriptionSettings);

    excludeProps.forEach(i => {
      if (Object.prototype.hasOwnProperty.call(settings, i)) {
        delete settings[i];
      }
    });

    if (settingId) {
      if (subscriptionSettings.subscriptionApplicationSettingId && newSubscriptionSettings) {
        newSubscriptionSettings['drm-view'] = newSubscriptionSettings['drm-view'] ? 'Y' : 'N';
        newSubscriptionSettings['username-watermark'] = newSubscriptionSettings['username-watermark'] ? 'Y' : 'N';
        newSubscriptionSettings['secret-watermark'] = newSubscriptionSettings['secret-watermark'] ? 'Y' : 'N';

        const Data = {
          group: {
            enable: newSubscriptionSettings.group,
          },
          'compass-point': {
            enable: newSubscriptionSettings['compass-point'],
          },
        };

        const LMSVideo = {
          'show-lms-video': newSubscriptionSettings['lmsvideo'] ? 'Y' : 'N',
        };

        delete newSubscriptionSettings.group;
        delete newSubscriptionSettings.lmsvideo;
        delete newSubscriptionSettings['compass-point'];

        const subResponse = yield call(serverAPI.updateSubscriptionSettings, subscriptionSettings.subscriptionApplicationSettingId, {
          ContentSecurity: JSON.stringify(newSubscriptionSettings),
          Data: JSON.stringify(Data),
          LMSVideo: JSON.stringify(LMSVideo),
        });

        if (subResponse.status !== 200) {
          return yield put(actions.SaveCompassSettingsFailed(response.data.responseData.message));
        }
      }

      response = yield call(serverAPI.updateApplicationSettings, settingId, settings);
    } else {
      return yield put(actions.SaveCompassSettingsSuccess('No accountApplicationAuthenticationSettingId is associated'));
    }
    if (response.status === 200) {
      if (action.hasNote) {
        const selectedUser = yield select(selectors.selectedUser);
        const { accountId } = selectedUser.account;
        const noteResponse = yield call(serverAPI.createNewAccountNote, getUpdateNotePayloadMap(accountId.toString(), action.hasNote));
        if (noteResponse.data.status !== '200') {
          return yield put(actions.SaveCompassSettingsFailed(updateNoteErrorMsg));
        }
      }
      return yield put(actions.SaveCompassSettingsSuccess(response.data.responseData.message));
    } else {
      yield put(actions.GetApplicationSettings());
      yield put(compassActions.GetSubscriptionSettings());
      return yield put(actions.SaveCompassSettingsFailed('Compass Settings Update Failed.'));
    }
  } catch (error) {
    yield put(actions.GetApplicationSettings());
    yield put(compassActions.GetSubscriptionSettings());
    return yield put(actions.SaveCompassSettingsFailed('failed to save.'));
  }
}

function* SaveSpecBuilderSettings(action: actionTypes.SaveSpecBuilderSettings) {
  try {
    let response;
    const settings = Object.assign({}, action.settings);
    const settingId = action.settings.accountApplicationAuthenticationSettingId;
    excludeProps.forEach(i => {
      if (Object.prototype.hasOwnProperty.call(settings, i)) {
        delete settings[i];
      }
    });
    if (settingId) {
      settings.status = JSON.stringify(settings.status);

      response = yield call(serverAPI.updateApplicationSettings, settingId, settings);
    } else {
      return yield put(actions.SaveSpecBuilderSettingsSuccess('No accountApplicationAuthenticationSettingId is associated'));
    }
    if (response.status === 200) {
      return yield put(actions.SaveSpecBuilderSettingsSuccess(response.data.responseData.message));
    } else {
      yield put(actions.GetApplicationSettings());
      yield put(compassActions.GetSubscriptionSettings());
      return yield put(actions.SaveSpecBuilderSettingsFailed(response.message || response.data.message));
    }
  } catch (error) {
    yield put(actions.GetApplicationSettings());
    yield put(compassActions.GetSubscriptionSettings());
    return yield put(actions.SaveSpecBuilderSettingsFailed(error));
  }
}

export function* GetApplicationSettings(action: actionTypes.GetApplicationSettings) {
  try {
    const selectedUser = yield select(selectors.selectedUser);
    if (!selectedUser.accountAuthenticationSetting) {
      return yield put(actions.GetApplicationSettingsSuccess([]));
    }
    yield put(actions.GetApplicationSettingsInprogress());
    const response = yield call(serverAPI.getApplicationSettings, selectedUser.accountAuthenticationSetting.accountAuthenticationSettingId);
    if (response.status === 200) {
      return yield put(actions.GetApplicationSettingsSuccess(response.data.responseData.value));
    } else {
      return yield put(actions.GetApplicationSettingsFailed('failed to to get app settings'));
    }
  } catch (error) {
    return yield put(actions.GetApplicationSettingsFailed('failed to to get app settings'));
  }
}

export function* GetSubscriptionSettings(action: compassActionTypes.GetSubscriptionSettings) {
  try {
    const selectedUser = yield select(selectors.selectedUser);
    if (!selectedUser.account || (selectedUser.account && !selectedUser.account.accountId)) {
      return yield put(compassActions.GetSubscriptionSettingsSuccess({}));
    }

    const { accountId } = selectedUser.account;
    yield put(compassActions.GetSubscriptionSettingsInprogress());
    const response = yield call(serverAPI.getSubscriptionSettings, accountId);

    if (response.status === 200) {
      return yield put(compassActions.GetSubscriptionSettingsSuccess(response.data.responseData.value));
    } else if (response.status === 400) {
      return yield put(compassActions.GetSubscriptionSettingsFailed("Account Settings doesn't exist for this account"));
    } else {
      return yield put(compassActions.GetSubscriptionSettingsFailed('Failed to get subscription settings'));
    }
  } catch (error) {
    return yield put(compassActions.GetSubscriptionSettingsFailed('Failed to get subscription settings'));
  }
}

function* WatchSettingsPage() {
  yield takeEvery(actionTypes.SAVE_COMPASS_SETTINGS, SaveCompassSettings);
  yield takeEvery(actionTypes.SAVE_SPEC_BUILDER_SETTINGS, SaveSpecBuilderSettings);
  yield takeLatest(actionTypes.GET_APPLICATION_SETTINGS, GetApplicationSettings);
  yield takeLatest(compassActionTypes.GET_SUBSCRIPTION_SETTINGS, GetSubscriptionSettings);
}

export default function* settingsSaga() {
  yield fork(WatchSettingsPage);
}
