import { combineReducers } from 'redux';
import * as actions from './actionTypes';

export const ipRanges = (state: any[] = [], action): any[] => {
  switch (action.type) {
    case actions.ON_POPULATE_IPS:
      return [state, ...action.ipSelections];
    case actions.ON_DELETE_IP_RANGE:
      return state.filter(i => i.id !== action.payload);
    case actions.ON_IP_POPULATION_FAILED:
      return [];
    default:
      return state;
  }
};

export const ipRows = (state: any[] = [], action): any[] => {
  switch (action.type) {
    case actions.ON_ADD_IP_ROW:
      return [...action.payload];
    case actions.ON_REMOVE_IP_ROW:
      return state.filter(i => i.id !== action.payload);
    default:
      return state;
  }
};

export const ipConflicts = (state: any[] = [], action: actions.IpGeneratorAction): any[] => {
  switch (action.type) {
    case actions.ON_IP_CONFLICTS:
      return action.conflicts;
    case actions.ON_IP_POPULATION_SUCCESS:
      return [];
    default:
      return state;
  }
};

export const ipGenerator = combineReducers({
  ipRanges,
  ipRows,
  ipConflicts,
});

export default ipGenerator;

export type IIpGeneratorState = ReturnType<typeof ipGenerator>;
