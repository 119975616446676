import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import NotificationType from '../../model/enumerations/notification.model';
import './notification.scss';
import { hideNotification } from '../../reducers/notification.reducer';

const Notification = () => {
  const notification = useSelector((state: IRootState) => state.notificationReducer);
  const dispatch = useDispatch();
  let typeClass = '';

  useEffect(() => {
    const ref = setTimeout(() => {
      dispatch(hideNotification());
    }, 7000);

    return () => {
      if (ref) {
        clearTimeout(ref);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const closeNotication = () => {
    dispatch(hideNotification());
  };

  switch (notification.type) {
    case NotificationType.INFO:
      typeClass = 'bg-info--light';
      break;
    case NotificationType.SUCCESS:
      typeClass = 'bg-success--light';
      break;
    case NotificationType.ERROR:
      typeClass = 'bg-danger--light';
      break;
    default:
      typeClass = 'bg-info--light';
      break;
  }

  return (
    <>
      {notification.showNotification && (
        <div className={`alert astm-alert app-notification ${typeClass} shadow`} role="alert">
          <span className="astm-alert-text pr-2">{notification.message}</span>
          <button type="button" className="close" aria-label="Close" onClick={closeNotication}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </>
  );
};

export default Notification;
