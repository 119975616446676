import { put, call, takeEvery, fork, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as userSearchActions from '../../user-search/data/actions';
import * as selectors from './selector';
import * as ServerAPI from '../../../services/serverAPI';
import { getUpdateNotePayloadMap } from 'app/shared/util/common-utils';
import { updateNoteErrorMsg } from 'app/config/constants';

const excludeProps = [
  'createdById',
  'createdDate',
  'modifiedById',
  'modifiedDate',
  'accountAuthenticationSettingId',
  'accountId',
  'orgUserId',
];

function* SaveIPScreen(action: actionTypes.SaveIPScreen) {
  try {
    const selectedUser = yield select(selectors.selectedUser);
    const { accountId } = selectedUser.account;

    const settings = {
      ...selectedUser.accountAuthenticationSetting,
      api: { ip: action.data.apiByIP },
      jwt: { 'jwt-enabled': action.data.jwtEnabled, 'jwt-test-login': action.data.jwtTestLogin },
      status: {
        'authentication-status': action.data.updateAuthStatus,
      },
    };
    settings.fedSSO = action.data.updateFedSSo;
    settings.applet = {
      AccountAppletId: '123',
      RefferralURL: action.data.updateApplet.split(','),
    };

    excludeProps.forEach(i => {
      if (Object.prototype.hasOwnProperty.call(settings, i)) {
        delete settings[i];
      }
    });

    settings.applet && delete settings.applet.AccountAppletId;
    settings.flag !== undefined && delete settings.flag;

    const response = yield call(ServerAPI.updateAccountAuthenticationSetting, selectedUser.account.accountId, settings);

    if (response.status === 200) {
      yield put(actions.onUpdateAuthStatus(action.data));
      yield put(actions.OnUpdateFedSSO(action.data.updateFedSSo));
      yield put(actions.OnUpdateApplet(action.data.updateApplet));

      if (action.data.hasNote) {
        const noteResponse = yield call(ServerAPI.createNewAccountNote, getUpdateNotePayloadMap(accountId.toString(), action.data.hasNote));
        if (noteResponse.data.status !== '200') {
          return yield put(actions.OnSaveFailed(updateNoteErrorMsg));
        }
      }
      return yield put(actions.SuccessSaveIPScreen(response.data.responseData.value.message));
    } else {
      yield put(userSearchActions.OnPopulateAccountById(`${accountId}`));
      return yield put(actions.OnSaveFailed('failed to save data, please try again'));
    }
  } catch (ex) {
    const selectedUser = yield select(selectors.selectedUser);
    const { accountId } = selectedUser.account;
    if (accountId) {
      yield put(userSearchActions.OnPopulateAccountById(`${accountId}`));
    }
    return yield put(actions.OnSaveFailed('failed to save data, please try again'));
  }
}

function* BuildRemoteLogin(action) {
  try {
    const selectedUser = yield select(selectors.selectedUser);

    const response = yield call(ServerAPI.downloadFile, selectedUser.account.accountId, action.getNewVersion);

    if (response.status === 200) {
      const { presignedUrl } = response.data.responseData && response.data.responseData.value;

      if (presignedUrl) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = presignedUrl;
        link.setAttribute('download', '');

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link); // cleanup
      }
    } else if (response.data.responseData && response.data.responseData.message) {
      return yield put(actions.BuildRemoteLoginFailed(response.data.responseData.message));
    } else {
      return yield put(actions.BuildRemoteLoginFailed('failed to download applet file'));
    }
  } catch (error) {
    return yield put(actions.BuildRemoteLoginFailed('failed to download applet file'));
  }
}

function* watchIPPage() {
  yield takeEvery(actionTypes.SAVE_IP_SCREEN, SaveIPScreen);
  yield takeEvery(actionTypes.BUILD_REMOTE_LOGIN, BuildRemoteLogin);
}

export default function* IPSaga() {
  yield fork(watchIPPage);
}
