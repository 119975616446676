import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './menu-components.scss';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id}>
    <DropdownToggle nav className="d-flex align-items-center standerd-brower-hide-style">
      {props.icon ? <i className={`${props.icon} menu-logo color-abstract-6`}></i> : <></>}
      <span className="user_name astm-type-body mobile-hide-style">{props.name}</span>
    </DropdownToggle>
    <DropdownToggle nav caret className="d-flex align-items-center mobile-hide-style header-dropdown">
      {props.icon ? <i className={`${props.icon} color-abstract-6`}></i> : <></>}
      <span className="user_name astm-type-body mobile-hide-style">{props.name}</span>
    </DropdownToggle>
    {props.noMenu ? (
      <></>
    ) : (
      <DropdownMenu right style={props.style}>
        {props.children}
      </DropdownMenu>
    )}
  </UncontrolledDropdown>
);
