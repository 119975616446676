import { isPromise, translate } from 'react-jhipster';
import { showNotification } from 'app/shared/reducers/notification.reducer';
import NotificationType from 'app/shared/model/enumerations/notification.model';

const addErrorAlert = (next, message, key?, data?) => {
  key = key ? key : message;
  next(showNotification({ type: NotificationType.ERROR, message: translate(key, data) }));
};
export default () => next => action => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    const meta = action.meta || action.payload?.meta;
    if (meta && meta.successMessage) {
      next(showNotification({ type: NotificationType.SUCCESS, message: meta.successMessage }));
    } else if (meta && meta.errorMessage) {
      next(showNotification({ type: NotificationType.ERROR, message: meta.errorMessage }));
    }
    return next(action);
  }

  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then(response => {
      if (action.meta && action.meta.successMessage) {
        next(showNotification({ type: NotificationType.SUCCESS, message: action.meta.successMessage }));
        // } else if (response && response.action && response.action.payload && response.action.payload.headers) {
        //   const headers = response.action.payload.headers;
        //   let alert: string = null;
        //   let alertParams: string = null;
        //   Object.entries(headers).forEach(([k, v]: [string, string]) => {
        //     if (k.toLowerCase().endsWith('app-alert')) {
        //       alert = v;
        //     } else if (k.toLowerCase().endsWith('app-params')) {
        //       alertParams = decodeURIComponent(v.replace(/\+/g, ' '));
        //     }
        //   });
        //   if (alert) {
        //     const alertParam = alertParams;
        //     next(showNotification(NotificationType.SUCCESS, translate(alert, { param: alertParam })));
        //   }
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      if (action.meta && action.meta.errorMessage) {
        next(showNotification({ type: NotificationType.ERROR, message: action.meta.errorMessage }));
      } else if (error && error.response) {
        const response = error.response;
        const data = response.data;
        if (!(response.status === 401 && (error.message === '' || (data && data.path && data.path.includes('/api/account'))))) {
          let i;
          switch (response.status) {
            // connection refused, server not reachable
            case 0:
              addErrorAlert(next, 'Server not reachable', 'error.server.not.reachable');
              break;

            case 400: {
              const headers = Object.entries(response.headers);
              let errorHeader = null;
              let entityKey = null;
              headers.forEach(([k, v]: [string, string]) => {
                if (k.toLowerCase().endsWith('app-error')) {
                  errorHeader = v;
                } else if (k.toLowerCase().endsWith('app-params')) {
                  entityKey = v;
                }
              });
              if (errorHeader) {
                const entityName = translate(`global.menu.entities.${entityKey}`);
                addErrorAlert(next, errorHeader, errorHeader, { entityName });
              } else if (data !== '' && data.fieldErrors) {
                const fieldErrors = data.fieldErrors;
                for (i = 0; i < fieldErrors.length; i++) {
                  const fieldError = fieldErrors[i];
                  if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                    fieldError.message = 'Size';
                  }
                  // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                  const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                  const fieldName = translate(`compassApp.${fieldError.objectName}.${convertedField}`);
                  addErrorAlert(next, `Error on field "${fieldName}"`, `error.${fieldError.message}`, { fieldName });
                }
              } else if (data !== '' && data.message) {
                addErrorAlert(next, data.message, data.message, data.params);
              } else {
                addErrorAlert(next, data);
              }
              break;
            }
            case 404:
              addErrorAlert(next, 'Not found', 'error.url.not.found');
              break;

            default:
              if (data !== '' && data.message) {
                addErrorAlert(next, data.message);
              } else {
                addErrorAlert(next, data);
              }
          }
        }
      } else if (error && error.config && error.config.url === 'api/account' && error.config.method === 'get') {
        /* eslint-disable no-console */
        console.log('Authentication Error: Trying to access url api/account with GET.');
      } else if (error && error.message) {
        next(showNotification({ type: NotificationType.ERROR, message: error.message }));
      } else {
        next(showNotification({ type: NotificationType.ERROR, message: 'Unknown error!' }));
      }
      return Promise.reject(error);
    });
};
