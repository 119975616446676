/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import * as types from './actionTypes';
import * as homeTypes from 'app/modules/pages/landing-home/data/actionTypes';
import * as ipTypes from 'app/modules/pages/ip/data/actionTypes';

export const userAccounts = (state: any = [], action: types.SearchPageActionTypes) => {
  switch (action.type) {
    case types.ON_SEARCH_SUCCESS:
      return action.results.customers;
    default:
      return state;
  }
};

export const searchSelections = (state: any = {}, action: types.SearchPageActionTypes) => {
  switch (action.type) {
    case types.ON_SEARCH:
      return action.selections;
    default:
      return state;
  }
};

export const searchResults = (
  state: IResultModel = { users: [], customers: [], columnsConfig: 0, emailSearchResult: [], ip: [], totalElements: 0 },
  action: types.SearchPageActionTypes | homeTypes.tenantsActionTypes
): IResultModel => {
  switch (action.type) {
    case types.ON_SEARCH_SUCCESS:
      return action.results;
    case types.ON_SEARCH:
    case homeTypes.ON_TENANT_SELECTION:
      return { users: [], customers: [], columnsConfig: 0, emailSearchResult: [], ip: [], totalElements: 0 };
    default:
      return state;
  }
};

export const selectedUser = (state: any = {}, action: types.SearchPageActionTypes | ipTypes.IpScreenAction) => {
  switch (action.type) {
    case types.ON_USER_ACCOUNT_SELECTED:
      return action.payload;
    case ipTypes.ON_POPULATE_RANGE_IPS:
      const ip = {
        rangeIPs: [...(state.accountAuthenticationSetting?.ip?.rangeIPs || []), action.ipSelections],
        singleIPs: state.accountAuthenticationSetting?.ip?.singleIPs || [],
      };
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          ip,
        },
      };
    case ipTypes.ON_POPULATE_SINGLE_IPS:
      const addedSingleIp = {
        singleIPs: [...(state.accountAuthenticationSetting?.ip?.singleIPs || []), action.ipSelections],
        rangeIPs: state.accountAuthenticationSetting?.ip?.rangeIPs || [],
      };
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          ip: addedSingleIp,
        },
      };
    case ipTypes.ON_REMOVE_RANGE_IP_ROW:
      const ipd = {
        rangeIPs: state.accountAuthenticationSetting.ip.rangeIPs.filter((i, index) => index !== action.index),
        singleIPs: state.accountAuthenticationSetting.ip.singleIPs,
      };
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          ip: ipd,
        },
      };
    case ipTypes.ON_REMOVE_SINGLE_IP_ROW:
      const ips = {
        singleIPs: state.accountAuthenticationSetting.ip.singleIPs.filter((i, index) => index !== action.index),
        rangeIPs: state.accountAuthenticationSetting.ip.rangeIPs,
      };
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          ip: ips,
        },
      };
    case ipTypes.UPDATE_BASIC_AUTH:
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          api: {
            ...state.accountAuthenticationSetting.api,
            ip: action.payload.apiByIP,
          },
          status: {
            ...state.accountAuthenticationSetting.status,
            'authentication-status': action.payload.updateAuthStatus,
          },
          jwt: {
            ...state.accountAuthenticationSetting.jwt,
            'jwt-enabled': action.payload.jwtEnabled,
            'jwt-test-login': action.payload.jwtTestLogin,
          },
        },
      };
    case ipTypes.UPDATE_FED_SSO:
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          fedSSO: action.payload,
        },
      };
    case ipTypes.UPDATE_JWT:
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          jwt: { 'jwt-enabled': action.payload },
        },
      };
    case ipTypes.UPDATE_TENANT_ACC_NUM:
      return {
        ...state,
        account: {
          ...state.account,
          tenantAccountNumber: action.payload.accountNumber,
          partnerId: action.payload.partnerId,
        },
      };
    case ipTypes.UPDATE_APPLET:
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          applet: {
            AccountAppletId: '123',
            RefferralURL: action.payload.split(','),
          },
        },
      };
    case ipTypes.SAVE_IP_SCREEN_SUCCESS:
    case ipTypes.SAVE_IP_SCREEN_FAILED:
      return {
        ...state,
        accountAuthenticationSetting: {
          ...state.accountAuthenticationSetting,
          flag: !state.accountAuthenticationSetting.flag,
        },
      };
    case types.ON_SEARCH:
      return {};
    default:
      return state;
  }
};

export const searchError = (state = '', action: types.SearchPageActionTypes): string => {
  switch (action.type) {
    case types.ON_SEARCH_FAILED:
      return action.errorMessage;
    case types.ON_SEARCH_SUCCESS:
    case types.SEARCH_PAGE_MOUNTED:
      return '';
    default:
      return state;
  }
};

export const searchResStatus = (state = null, action: types.SearchPageActionTypes): number => {
  switch (action.type) {
    case types.ON_SEARCH_FAILED:
    case types.ON_SEARCH_SUCCESS:
      return action.status || null;
    case types.SEARCH_PAGE_MOUNTED:
      return null;
    default:
      return state;
  }
};

export const searchInProgress = (state = false, action: types.SearchPageActionTypes): boolean => {
  switch (action.type) {
    case types.ON_SEARCH_IN_PROGRESS:
      return true;
    case types.ON_SEARCH_SUCCESS:
    case types.ON_SEARCH_FAILED:
      return false;
    default:
      return state;
  }
};

export const populateAccountInProgress = (state = false, action: types.SearchPageActionTypes): boolean => {
  switch (action.type) {
    case types.ON_POPULATE_ACCOUNT_IN_PROGRESS:
      return true;
    case types.ON_POPULATE_ACCOUNT_SUCCESS:
    case types.ON_POPULATE_ACCOUNT_FAILED:
      return false;
    default:
      return state;
  }
};

export const EncryptedAccountId = (state = '', action: types.SearchPageActionTypes | ipTypes.IpScreenAction) => {
  switch (action.type) {
    case types.ON_SEARCH_IN_PROGRESS:
    case types.ON_SEARCH_SUCCESS:
    case types.ON_SEARCH_FAILED:
    case ipTypes.BUILD_REMOTE_LOGIN:
    case ipTypes.BUILD_REMOTE_LOGIN_FAILED:
      return '';
    case ipTypes.BUILD_REMOTE_LOGIN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  searchLabel: '',
  searchValue: '',
};

export const userSearchParams = (state = initialState, action: any) => {
  switch (action.type) {
    case types.ON_USER_SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue,
        searchLabel: action.searchLabel,
      };
    default:
      return state;
  }
};

export const searchPage = combineReducers({
  userAccounts,
  searchSelections,
  searchResults,
  searchError,
  searchResStatus,
  searchInProgress,
  populateAccountInProgress,
  selectedUser,
  EncryptedAccountId,
  userSearchParams,
});

export default searchPage;

export type ISearchPageState = ReturnType<typeof searchPage>;
