import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  powerBiUrl:
    'https://app.powerbi.com/groups/me/reports/75a72174-159e-4674-abaf-5a6d8eaaf096/ReportSectiond64c9d2d9c734abde862?ctid=9b80e88e-980b-4d70-a21b-773d329f445d&filter=ProductQuery/ProductSKU eq ',
};

const slice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
});

export const reducer = slice.reducer;
export const actions = slice.actions;

export type IPropertiesState = ReturnType<typeof slice.reducer>;
