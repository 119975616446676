import { put, call, takeEvery, all, fork, select } from 'redux-saga/effects';
import * as API from 'app/modules/services/serverAPI';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { IRootState } from 'app/shared/reducers';

function* fetchStocks(action: actionTypes.GetStocks) {
  try {
    yield action.type === actionTypes.GET_STOCKS && put(actions.GetStocksInProgress());
    const accountId = yield select((state: IRootState) => {
      return state.entities.pages.searchPage.selectedUser.account.accountId;
    });

    const response = yield call(API.fetchStocks, { accountId });

    if (response.status === 200 || response.status === 204) {
      return yield put(actions.GetStocksSuccess(response.data.responseData.value || []));
    } else {
      if (response.data.responseData.message) {
        const message = JSON.parse(response.data.responseData.message);

        return yield put(actions.GetStocksFailed(message.message));
      } else {
        return yield put(actions.GetStocksFailed('Unexpected error occured, please try again'));
      }
    }
  } catch (error) {
    return yield put(actions.GetStocksFailed('Unexpected error occured, please try again'));
  }
}

function* updateStock(action: actionTypes.UpdateStock) {
  try {
    return yield put(actions.UpdateStockSuccess(action.stock));
  } catch (error) {
    return yield put(actions.UpdateStockFailed('Unexpected error occured, please try again'));
  }
}

function* updateStockStatus({ action }: actionTypes.UpdateStockStatus) {
  try {
    const { status } = action;
    yield put(actions.UpdateStockStatusInprogress());
    const res = yield call(API.updateStockStatusCall, action.data.productItemEntitlementId, { status: status ? 'Active' : 'Inactive' });

    if (res.status === 200) {
      return yield put(actions.updateStockStatusSuccess('Status has been updated successfully'));
    } else {
      return yield put(actions.updateStockStatusFailed(res.data.message || 'Unable to update status'));
    }
  } catch (error) {
    return yield put(actions.updateStockStatusFailed('Unable to update status'));
  }
}

function* updateStockGrace(action: actionTypes.UpdateStockGrace) {
  try {
    const updates = [];
    yield put(actions.UpdateStockStatusInprogress());
    for (const stock of action.stocks) {
      updates.push(call(API.updateStockGracePeriod, { stock }));
    }

    const response = yield all(updates);

    const responseError = response.filter(item => item.status !== 200);
    if (responseError.length > 0) {
      return yield put(actions.UpdateStockGraceFailed('Failed to assign grace period to one or more selected entitlements.'));
    }

    return yield put(actions.UpdateStockGraceSuccess('Grace period assigned successfully to all the selected entitlements.'));
  } catch (error) {
    return yield put(actions.UpdateStockGraceFailed('Unexpected error occured, please try again'));
  }
}

function* watchStocksPage() {
  yield takeEvery(
    [
      actionTypes.GET_STOCKS,
      actionTypes.UPDATE_STOCK_STATUS_SUCCESS,
      actionTypes.UPDATE_STOCK_GRACE_SUCCESS,
      actionTypes.UPDATE_STOCK_GRACE_FAILED,
    ],
    fetchStocks
  );
  yield takeEvery(actionTypes.UPDATE_STOCK, updateStock);
  yield takeEvery(actionTypes.UPDATE_STOCK_STATUS, updateStockStatus);
  yield takeEvery(actionTypes.UPDATE_STOCK_GRACE, updateStockGrace);
}

export default function* stocksSaga() {
  yield fork(watchStocksPage);
}
