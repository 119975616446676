import { IAccountSetting } from '../accountTypes';
import * as actions from './actionTypes';

export function OnUpdateAccountSettings(
  accountId: number,
  accountSettingId: number,
  dataToUpdate: IAccountSetting,
  accountData: any,
  cbfn: Function,
  hasNote: string
): actions.OnUpdateAccountSettings {
  return {
    type: actions.ON_UPDATE_ACCOUNT_SETTINGS,
    accountId,
    accountSettingId,
    accountSetting: dataToUpdate,
    accountData,
    cbfn,
    hasNote,
  };
}

export const OnUpdateSuccess = (message: string): actions.OnUpdateSuccess => ({
  type: actions.ON_UPDATE_SUCCESS,
  meta: { successMessage: message },
});

export function OnUpdateFailed(error: string): actions.OnUpdateFailed {
  return {
    type: actions.ON_UPDATE_FAILED,
    meta: { errorMessage: error },
  };
}

export function OnUpdateInprogress() {
  return {
    type: actions.ON_UPDATE_INPROGRESS,
  };
}
