export const GET_COMPASS_SETTINGS = 'pages/appsettings/GET_COMPASS_SETTINGS';
export const UPDATE_COMPASS_SETTINGS = 'pages/appsettings/UPDATE_COMPASS_SETTINGS';

export const GET_SPEC_BUILDER_SETTINGS = 'pages/appsettings/GET_SPEC_BUILDER_SETTINGS';
export const UPDATE_SPEC_BUILDER_SETTINGS = 'pages/appsettings/UPDATE_SPEC_BUILDER_SETTINGS';

export const SAVE_SPEC_BUILDER_SETTINGS = 'pages/appsettings/SAVE_SPEC_BUILDER_SETTINGS';
export const SAVE_COMPASS_SETTINGS = 'pages/appsettings/SAVE_COMPASS_SETTINGS';

export const SAVE_SPEC_BUILDER_SETTINGS_SUCCESS = 'pages/appsettings/SAVE_SPEC_BUILDER_SETTINGS_SUCCESS';
export const SAVE_COMPASS_SETTINGS_SUCCESS = 'pages/appsettings/SAVE_COMPASS_SETTINGS_SUCCESS';

export const GET_APPLICATION_SETTINGS = 'pages/appsettings/GET_APPLICATION_SETTINGS';
export const GET_APPLICATION_SETTINGS_SUCCESS = 'pages/appsettings/GET_APPLICATION_SETTINGS_SUCCESS';
export const GET_APPLICATION_SETTINGS_FAILED = 'pages/appsettings/GET_APPLICATION_SETTINGS_FAILED';
export const GET_SUBSCRIPTION_SETTINGS = 'pages/appsettings/GET_SUBSCRIPTION_SETTINGS';
export const GET_SUBSCRIPTION_SETTINGS_INPROGRESS = 'pages/appsettings/GET_SUBSCRIPTION_SETTINGS_INPROGRESS';
export const GET_SUBSCRIPTION_SETTINGS_SUCCESS = 'pages/appsettings/GET_SUBSCRIPTION_SETTINGS_SUCCESS';
export const GET_SUBSCRIPTION_SETTINGS_FAILED = 'pages/appsettings/GET_SUBSCRIPTION_SETTINGS_FAILED';
export const SAVE_COMPASS_SETTINGS_FAILED = 'pages/appsettings/SAVE_COMPASS_SETTINGS_FAILED';
export const SAVE_SPEC_BUILDER_SETTINGS_FAILED = 'SAVE_SPEC_BUILDER_SETTINGS_FAILED';
export const GET_APPLICATION_SETTINGS_INPROGRESS = 'pages/appsettings/GET_APPLICATION_SETTINGS_INPROGRESS';

export interface GetCompassSettings {
  type: typeof GET_COMPASS_SETTINGS;
}

export interface UpdateCompassSettings {
  type: typeof UPDATE_COMPASS_SETTINGS;
  item: ICompassSettingsModel;
}

export interface GetSpecBuilderSettings {
  type: typeof GET_SPEC_BUILDER_SETTINGS;
}

export interface UpdateSpecBuilderSettings {
  type: typeof UPDATE_SPEC_BUILDER_SETTINGS;
  item: ISpecBuilderSettingsModel;
}

export interface SaveCompassSettings {
  type: typeof SAVE_COMPASS_SETTINGS;
  settings: any;
}

export interface SaveSpecBuilderSettings {
  type: typeof SAVE_SPEC_BUILDER_SETTINGS;
  settings: any;
}

export interface SaveCompassSettingsSuccess {
  type: typeof SAVE_COMPASS_SETTINGS_SUCCESS;
  meta: { successMessage: string };
}

export interface SaveSpecBuilderSettingsSuccess {
  type: typeof SAVE_SPEC_BUILDER_SETTINGS_SUCCESS;
  meta: { successMessage: string };
}

export interface GetApplicationSettings {
  type: typeof GET_APPLICATION_SETTINGS;
}

export interface GetSubscriptionSettings {
  type: typeof GET_SUBSCRIPTION_SETTINGS;
}

export interface GetSubscriptionSettingsInprogress {
  type: typeof GET_SUBSCRIPTION_SETTINGS_INPROGRESS;
}

export interface GetSubscriptionSettingsSuccess {
  type: typeof GET_SUBSCRIPTION_SETTINGS_SUCCESS;
  payload: any;
}

export interface GetSubscriptionSettingsFailed {
  type: typeof GET_SUBSCRIPTION_SETTINGS_FAILED;
  meta: { errorMessage: string };
}

export interface GetApplicationSettingsInprogress {
  type: typeof GET_APPLICATION_SETTINGS_INPROGRESS;
}

export interface GetApplicationSettingsSuccess {
  type: typeof GET_APPLICATION_SETTINGS_SUCCESS;
  payload: any;
}

export interface GetApplicationSettingsFailed {
  type: typeof GET_APPLICATION_SETTINGS_FAILED;
  meta: { errorMessage: string };
}

export interface SaveCompassSettingsFailed {
  type: typeof SAVE_COMPASS_SETTINGS_FAILED;
  meta: { errorMessage: string };
}

export interface SaveSpecBuilderSettingsFailed {
  type: typeof SAVE_SPEC_BUILDER_SETTINGS_FAILED;
  meta: { errorMessage: string };
}

export type appSettingActionTypes =
  | GetCompassSettings
  | UpdateCompassSettings
  | GetSpecBuilderSettings
  | UpdateSpecBuilderSettings
  | SaveCompassSettings
  | SaveSpecBuilderSettings
  | SaveCompassSettingsSuccess
  | SaveSpecBuilderSettingsSuccess
  | GetApplicationSettings
  | GetApplicationSettingsSuccess
  | GetApplicationSettingsFailed
  | GetSubscriptionSettings
  | GetSubscriptionSettingsInprogress
  | GetSubscriptionSettingsSuccess
  | GetSubscriptionSettingsFailed
  | SaveCompassSettingsFailed
  | SaveSpecBuilderSettingsFailed
  | GetApplicationSettingsInprogress;
