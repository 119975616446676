import { SaveIPScreenFailed, SuccessSaveIPScreen } from '../../ip/data/actionTypes';

export const ON_SEARCH = 'pages/user-search/ON_SEARCH';
export const ON_POPULATE_ACCOUNT_BY_ID = 'pages/user-search/ON_POPULATE_ACCOUNT_BY_ID';
export const ON_POPULATE_ACCOUNT_IN_PROGRESS = 'pages/user-search/ON_POPULATE_ACCOUNT_IN_PROGRESS';
export const ON_POPULATE_ACCOUNT_SUCCESS = 'pages/user-search/ON_POPULATE_ACCOUNT_SUCCESS';
export const ON_POPULATE_ACCOUNT_FAILED = 'pages/user-search/ON_POPULATE_ACCOUNT_FAILED';
export const ON_SEARCH_EMAIL_USERNAME = 'pages/user-search/ON_SEARCH_EMAIL_USERNAME';
export const ON_SEARCH_SUCCESS = 'pages/user-search/ON_SEARCH_SUCCESS';
export const ON_SEARCH_FAILED = 'pages/user-search/ON_SEARCH_FAILED';
export const ON_SEARCH_IN_PROGRESS = 'pages/user-search/ON_SEARCH_IN_PROGRESS';
export const ON_USER_ACCOUNT_SELECTED = 'pages/user-search/ON_USER_ACCOUNT_SELECTED';
export const SEND_ACTIVATION_MAIL = 'pages/user-search/SEND_ACTIVATION_MAIL';
export const SEARCH_PAGE_MOUNTED = 'pages/user-search/SEARCH_PAGE_MOUNTED';
export const ON_USER_SEARCH_VALUE_UPDATE = 'pages/user-search/ON_USER_SEARCH_VALUE_UPDATE';

export interface onSearchData {
  selections: { value: string; item: { label: string; value: string }; pagination: { pageIndex: number; pageSize: number } };
}
export interface OnSearch {
  type: typeof ON_SEARCH;
  selections: { value: string; item: { label: string; value: string }; pagination: { pageIndex: number; pageSize: number } };
}

export interface OnUserSearchValueUpdate {
  type: typeof ON_USER_SEARCH_VALUE_UPDATE;
  payload: { searchValue: string | number; searchLabel: string };
}

export interface OnPopulateAccountById {
  type: typeof ON_POPULATE_ACCOUNT_BY_ID;
  selections: { value: string; item: { label: string; value: string }; pagination: { pageIndex: number; pageSize: number } };
}

export interface OnPopulateAccountInProgress {
  type: typeof ON_POPULATE_ACCOUNT_IN_PROGRESS;
}

export interface OnPopulateAccountSuccess {
  type: typeof ON_POPULATE_ACCOUNT_SUCCESS;
}

export interface OnPopulateAccountFailed {
  type: typeof ON_POPULATE_ACCOUNT_FAILED;
  errorMessage: string;
}

export interface OnEmailOrUsernameSearch {
  type: typeof ON_SEARCH_EMAIL_USERNAME;
  selections: { value: string; item: { label: string; value: string }; pagination: { pageIndex: number; pageSize: number } };
}

export interface OnSearchSuccess {
  type: typeof ON_SEARCH_SUCCESS;
  results: IResultModel;
  status?: number;
}

export interface OnSearchFailed {
  type: typeof ON_SEARCH_FAILED;
  errorMessage: string;
  status?: number;
}

export interface OnSearchInProgress {
  type: typeof ON_SEARCH_IN_PROGRESS;
}

export interface OnUserAccountSelected {
  type: typeof ON_USER_ACCOUNT_SELECTED;
  payload: ISearchResult;
  options: any;
}

export interface SendActivationMail {
  type: typeof SEND_ACTIVATION_MAIL;
  meta: {
    successMessage: string;
  };
}

export interface OnSearchMounted {
  type: typeof SEARCH_PAGE_MOUNTED;
}

export type SearchPageActionTypes =
  | OnSearch
  | OnSearchSuccess
  | OnSearchFailed
  | OnSearchInProgress
  | OnPopulateAccountInProgress
  | OnPopulateAccountSuccess
  | OnPopulateAccountFailed
  | OnUserAccountSelected
  | SendActivationMail
  | OnSearchMounted
  | SuccessSaveIPScreen
  | SaveIPScreenFailed
  | OnUserSearchValueUpdate;
