export const userDetails = {
  userName: 'JULIA PASKUS',
  companyName: 'ASTM International',
  loginId: '1664571',
  email: 'jpaskus@astm.org',
  industry: '',
  referralcode: 'ASTM',
  contact: '',
  manager: '',
  status: 'Active',
  lastActive: '2020-06-26 03:28:07',
};

export const industryTypes = [
  { label: 'Academic', value: 1 },
  { label: 'Corporate', value: 2 },
  { label: 'Government', value: 3 },
  { label: 'Not Applicable', value: 4 },
];

export const accountEntitlements = [
  {
    id: 1,
    checked: true,
    label: 'ASTM Membership',
  },
  {
    id: 2,
    checked: true,
    label: 'Public',
  },
  {
    id: 3,
    checked: true,
    label: 'Specbuider',
  },
  {
    id: 4,
    checked: true,
    label: 'Subscription',
  },
];

export const ipObject = {
  first: '',
  second: '',
  third: '',
  fourth: '',
  fifth: '',
  sixth: '',
  seventh: '',
  last: '',
  id: 1,
};

export const ipConflicts = [
  { ip: '130.160.23.23', account: '1177263' },
  { ip: '124.156.241.136', account: '1522356' },
  { ip: '241.31.61.15', account: '1342378' },
];

export const userData = [
  { email: 'bjohn@astm.org', fName: 'John', lName: 'bradie' },
  { email: 'halexa@astm.org', fName: 'alexa', lName: 'hector' },
  { email: 'rbrad@astm.org', fName: 'brad', lName: 'robert' },
  { email: 'anexus@astm.org', fName: 'nexus', lName: 'adam' },
];

export const searchOptions = [
  { value: 'Select', label: 'Select' },
  { value: 'Account Number', label: 'Account Number' },
  { value: 'Account Name', label: 'Account Name' },
  { value: 'Account Id', label: 'MAE Account ID' },
  { value: 'Email or Username', label: 'Email or Username' },
  { value: 'IP Address', label: 'IP Address' },
  { value: 'Tenant Account Number', label: 'Tenant Account Number' },
];

export const userSearchResults = [
  {
    UserName: 'JPASKUS@API.COM',
    Email: 'JPASKUS@API.ORG',
    ActivationStatus: 'ACTIVE',
    ACTION: false,
  },
  {
    UserName: 'JPASKUS@ASTM.COM',
    Email: 'JPASKUS@ASTM.ORG',
    ActivationStatus: 'PROVISIONED',
    ACTION: true,
  },
];

export const accountInfo = {
  status: '200',
  message: 'success',
  responseData: {
    pageIndex: 1,
    pageSize: 10,
    offset: 0,
    totalElements: 1,
    content: [
      {
        account: {
          createdDate: '2020-09-01T11:46:34.589423',
          modifiedDate: '2020-09-01T11:46:34.589423',
          accountId: 4879,
          name: 'Testilhaj',
          accountNumber: '3334628',
          accountTypeId: 1,
          ecommerceAccountNumber: '298',
          tenantId: 6,
        },
        accountSetting: {
          createdDate: '2020-09-01T11:46:36.868922',
          modifiedById: 1,
          modifiedDate: '2020-09-02T07:24:31.661646',
          accountSettingId: 1451,
          accountGroupId: 1,
          accountId: 4879,
          accountManagerId: 2,
          acronym: 'null',
          defaultLanguage: 'null',
          logo: 'null',
          accountStatus: 'Active',
          marketingPreference: 'null',
          industryId: 1,
        },
        accountAuthenticationSetting: {
          createdDate: '2020-09-01T11:46:38.678796',
          modifiedById: 1,
          modifiedDate: '2020-09-02T07:16:43.787013',
          accountAuthenticationSettingId: 1401,
          accountId: 4879,
          applet: {
            AccountAppletId: '123',
            RefferralURL: [],
          },
          basicAuth: {
            'basic-auth-enabled': 'N',
          },
          fedSSO: {
            IDPEntityID: 'test1181',
          },
          ip: {
            rangeIPs: [],
          },
          jwt: {
            'jwt-enabled': 'N',
          },
          orgUserId: 123,
        },
      },
    ],
    totalPages: 1,
  },
};
