import './input.scss';
import React, { useState } from 'react';

interface InputSearchProps {
  name: string;
  placeHolder: string;
  onSearch?: (event: any) => void;
  readonly?: boolean;
  initValue?: string;
}

export const InputSearch = (props: InputSearchProps) => {
  const [search, setSearch] = useState(props.initValue || '');
  const handleSearch = () => {
    if (props.onSearch) props.onSearch(search.trim());
  };

  const handleKeyUp = event => {
    // on Enter
    if (event.key === 'Enter') handleSearch();
  };

  return (
    <span className="search d-flex">
      <input
        type="text"
        className="form-control search-input"
        value={search}
        onKeyUp={handleKeyUp}
        onChange={({ target }) => setSearch(target.value)}
        placeholder={props.placeHolder}
        readOnly={props.readonly}
        name={props.name}
      />
      <button className="search-button btn astm-btn btn-primary" aria-label="search" onClick={handleSearch} disabled={props.readonly}>
        <i className="fas fa-search"></i>
      </button>
    </span>
  );
};
