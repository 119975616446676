/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import * as types from './actionTypes';

interface IState {
  stocks: IStockModel[];
  loading: boolean;
  stockUpdateLoader: boolean;
  bulkGracePeriodSelectionOption: ISelectOption;
}

const initialState = {
  stocks: [],
  loading: false,
  stockUpdateLoader: false,
  bulkGracePeriodSelectionOption: undefined,
};

export const stocksDefaultState = initialState;

export const stocks = (state: IState = initialState, action: types.stockActionTypes): IState => {
  switch (action.type) {
    case types.GET_STOCKS_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_STOCKS_SUCCESS:
      return {
        ...state,
        loading: false,
        stocks: action.result,
        stockUpdateLoader: false,
      };
    case types.UPDATE_STOCK_SUCCESS:
      return {
        ...state,
        stocks: state.stocks.map(a => (a.productItemEntitlementId === action.stock.productItemEntitlementId ? action.stock : a)),
      };
    case types.UPDATE_STOCK_STATUS_INPROGRESS:
      return {
        ...state,
        stockUpdateLoader: true,
      };
    case types.GET_STOCKS_FAILED:
      return {
        ...state,
        loading: false,
        stockUpdateLoader: false,
      };
    case types.SAVE_STOCK_UPDATES_SUCCESS:
      return state;
    case types.GET_STOCKS:
      return {
        ...state,
        stocks: [],
      };
    case types.BULK_STOCK_GRACE_PERIOD_SELECTION:
      return {
        ...state,
        bulkGracePeriodSelectionOption: action.gracePeriod,
      };
    case types.UPDATE_STOCK_GRACE_FAILED:
      return {
        ...state,
        stockUpdateLoader: false,
        loading: false,
      };
    default:
      return state;
  }
};

export const expiryFilter = (state = false, action: types.stockActionTypes): boolean => {
  switch (action.type) {
    case types.ON_FILTER_ACCESS_EXPIRY:
      return action.status;
    case types.GET_STOCKS:
      return false;
    default:
      return state;
  }
};

export const stocksPage = combineReducers({
  stocks,
  expiryFilter,
});

export default stocksPage;

export type IStocksPageState = ReturnType<typeof stocksPage>;
