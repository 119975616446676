import { combineReducers } from 'redux';
import * as types from './actionTypes';

export const tenants = (state: ITenantsModel[] = [], action: types.tenantsActionTypes): ITenantsModel[] => {
  switch (action.type) {
    case types.GET_TENANTS_SUCCESS:
      return action.tenants;
    default:
      return state;
  }
};

export const selectedTenant = (state = {}, action: types.tenantsActionTypes): any => {
  switch (action.type) {
    case types.ON_TENANT_SELECTION:
      return action.tenant;
    default:
      return state;
  }
};

export const tenantLoading = (state = false, action: any): any => {
  switch (action.type) {
    case types.GET_TENANTS_IN_PROGRESS:
      return action.payload;
    default:
      return state;
  }
};

export const tenantSelectionPage = combineReducers({
  tenants,
  selectedTenant,
  tenantLoading,
});

export default tenantSelectionPage;

export type ITenantSelectionPageState = ReturnType<typeof tenantSelectionPage>;
