import React from 'react';
interface IProps {
  list: any;
  onRowDelete: (item: any, index: number) => any;
}

export const IpList = (props: IProps) => {
  const tableHeaders = () => {
    return (
      <thead className="bg-grey">
        <tr>
          <th>Start IP</th>
          <th>End IP</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
    );
  };

  const generateRow = (item, index) => {
    const { startIP, endIP } = item;
    return (
      <tr key={index}>
        <td className="font-weight-bold">{startIP}</td>
        <td className="font-weight-bold">{endIP}</td>
        <td className="text-center">
          <button
            type="button"
            className="btn btn-link btn-xs text-secondary"
            id="ip-list-delete"
            style={{ borderRadius: '4px' }}
            value="Delete"
            onClick={() => props.onRowDelete(item, index)}
          >
            <i className="fas fa-trash"></i> Delete
          </button>
        </td>
      </tr>
    );
  };

  return props.list && props.list.length > 0 ? (
    <div className="">
      <div className="row col-md-8 custyle">
        <p>Range IPs</p>
        <table className="table astm-table table-striped">
          {tableHeaders()}
          <tbody>{props.list && props.list.map((i, index) => generateRow(i, index))}</tbody>
        </table>
      </div>
    </div>
  ) : null;
};
