import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as API from 'app/modules/services/serverAPI';
import * as ipActions from '../../../pages/ip/data/actions';
import { updateNoteErrorMsg } from 'app/config/constants';
import { getUpdateNotePayloadMap } from 'app/shared/util/common-utils';

export function* onSaveUser(action: actionTypes.OnUpdateAccountSettings) {
  try {
    yield put(actions.OnUpdateInprogress());
    const response = yield call(API.updateAccountSettings, action.accountId, action.accountSettingId, action.accountSetting);
    const acocuntUpdateResponse = yield call(API.updateAccount, action.accountId, action.accountData);
    if (acocuntUpdateResponse && acocuntUpdateResponse.data && acocuntUpdateResponse.data.status === '200') {
      yield put(ipActions.OnUpdateTenantAccNum(action.accountData.tenantAccountNumber, action.accountData.partnerId));
    }
    if (
      response &&
      response.data &&
      response.data.status === '200' &&
      acocuntUpdateResponse &&
      acocuntUpdateResponse.data &&
      acocuntUpdateResponse.data.status === '200'
    ) {
      if (action.hasNote) {
        const noteResponse = yield call(API.createNewAccountNote, getUpdateNotePayloadMap(action.accountId.toString(), action.hasNote));
        if (noteResponse.data.status !== '200') {
          return yield put(actions.OnUpdateFailed(updateNoteErrorMsg));
        }
      }
      return yield put(actions.OnUpdateSuccess(response.data.message || 'Account Attributes Successfully Updated'));
    } else if (response.data.message) {
      action.cbfn();
      return yield put(actions.OnUpdateFailed('Account Attributes Update Failed.'));
    } else {
      action.cbfn();
      if (acocuntUpdateResponse.data.message.includes('TenantAccountNumber')) {
        return yield put(
          actions.OnUpdateFailed(
            'Error: Tenant / Reseller Account Number provided already exists for this tenant. Please provide a different number.'
          )
        );
      }
      return yield put(actions.OnUpdateFailed('Unexpected error'));
    }
  } catch (error) {
    action.cbfn();
    return yield put(actions.OnUpdateFailed('Unexpected error'));
  }
}

export default function* AttributeSaga() {
  yield takeLatest(actionTypes.ON_UPDATE_ACCOUNT_SETTINGS, onSaveUser);
}
