export const SAVE_IP_SCREEN = 'pages/ipscreen/SAVE_IP_SCREEN';
export const SAVE_IP_SCREEN_SUCCESS = 'pages/ipscreen/SAVE_IP_SCREEN_SUCCESS';
export const ON_POPULATE_SINGLE_IPS = 'components/ipscreen/ON_POPULATE_SINGLE_IPS';
export const ON_POPULATE_RANGE_IPS = 'components/ipscreen/ON_POPULATE_RANGE_IPS';
export const ON_REMOVE_RANGE_IP_ROW = 'components/ipscreen/ON_REMOVE_RANGE_IP_ROW';
export const ON_REMOVE_SINGLE_IP_ROW = 'components/ipscreen/ON_REMOVE_SINGLE_IP_ROW';
export const SAVE_IP_SCREEN_FAILED = 'components/ipscreen/SAVE_IP_SCREEN_FAILED';
export const UPDATE_BASIC_AUTH = 'components/ipscreen/UPDATE_BASIC_AUTH';
export const UPDATE_FED_SSO = 'components/ipscreen/UPDATE_FED_SSO';
export const UPDATE_JWT = 'components/ipscreen/UPDATE_JWT';
export const UPDATE_TENANT_ACC_NUM = 'components/ipscreen/UPDATE_TENANT_ACC_NUM';
export const UPDATE_APPLET = 'components/ipscreen/UPDATE_APPLET';
export const BUILD_REMOTE_LOGIN = 'components/ipscreen/BUILD_REMOTE_LOGIN';
export const BUILD_REMOTE_LOGIN_FAILED = 'components/ipscreen/BUILD_REMOTE_LOGIN_FAILED';
export const BUILD_REMOTE_LOGIN_SUCCESS = 'components/ipscreen/BUILD_REMOTE_LOGIN_SUCCESS';

export interface SaveIPScreen {
  type: typeof SAVE_IP_SCREEN;
  data: any;
}

export interface SuccessSaveIPScreen {
  type: typeof SAVE_IP_SCREEN_SUCCESS;
  meta: { successMessage: string };
}

export interface SaveIPScreenFailed {
  type: typeof SAVE_IP_SCREEN_FAILED;
  meta: { errorMessage: string };
}

export interface OnPopulateRangeIPs {
  type: typeof ON_POPULATE_RANGE_IPS;
  ipSelections: any;
}

export interface OnPopulateSingleIPs {
  type: typeof ON_POPULATE_SINGLE_IPS;
  ipSelections: any;
}
export interface OnRemoveRangeIPRow {
  type: typeof ON_REMOVE_RANGE_IP_ROW;
  item: any;
  index: number;
}

export interface OnRemoveSingleIPRow {
  type: typeof ON_REMOVE_SINGLE_IP_ROW;
  item: any;
  index: number;
}

export interface OnUpdateBasicAuth {
  type: typeof UPDATE_BASIC_AUTH;
  payload: any;
}

export interface OnUpdateFedSSO {
  type: typeof UPDATE_FED_SSO;
  payload: string;
}

export interface OnUpdateJWT {
  type: typeof UPDATE_JWT;
  payload: string;
}

export interface OnUpdateTenantAccNum {
  type: typeof UPDATE_TENANT_ACC_NUM;
  payload: any;
}

export interface OnUpdateApplet {
  type: typeof UPDATE_APPLET;
  payload: string;
}

export interface BuildRemoteLogin {
  type: typeof BUILD_REMOTE_LOGIN;
  payload: string;
}

export interface BuildRemoteLoginSuccess {
  type: typeof BUILD_REMOTE_LOGIN_SUCCESS;
  payload: string;
}

export interface BuildRemoteLoginFailed {
  type: typeof BUILD_REMOTE_LOGIN_FAILED;
  message: string;
}

export type IpScreenAction =
  | OnPopulateRangeIPs
  | OnPopulateSingleIPs
  | SaveIPScreen
  | SuccessSaveIPScreen
  | OnRemoveRangeIPRow
  | OnRemoveSingleIPRow
  | OnUpdateBasicAuth
  | OnUpdateFedSSO
  | OnUpdateJWT
  | OnUpdateApplet
  | BuildRemoteLogin
  | BuildRemoteLoginSuccess
  | BuildRemoteLoginFailed
  | OnUpdateTenantAccNum;
